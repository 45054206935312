import { mapActions, mapGetters, mapState } from 'vuex'

export const utils_mixin = {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({}),
        ...mapState({}),
    },
    methods: {
        deep_get(object, dotted_path, default_value = undefined) {
            if (object === undefined) {
                return default_value
            }
            const path = dotted_path.split('.')
            const [first_path, second_path] = path
            if (!second_path) {
                return object[first_path] || default_value
            } else {
                path.shift()
                const new_dotted_path = path.join('.')
                return this.deep_get(object[first_path], new_dotted_path, default_value)
            }
        },
        coach_unit() {
            const coach_units = {
                test: '649830c2d020117b884cabdf',
                prod: '649831642a27985d44ae3488',
            }
            if (window.location.host.includes('localhost')) {
                return coach_units['test']
            } else if (window.location.host.includes('test')) {
                return coach_units['test']
            } else {
                return coach_units['prod']
            }
        },
        ...mapActions([]),
    },
}
