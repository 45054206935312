<template>
    <v-row
        v-if="loading"
        class="mt-12"
        justify="center"
    >
        <v-progress-circular
            color="primary"
            indeterminate
        />
    </v-row>
    <v-row
        class="mt-12"
        justify="center"
        v-else-if="!permission"
    >
        <v-alert
            border="right"
            width="500"
            colored-border
            type="info"
            color="primary"
            elevation="2"
        >
            Behörighet saknas för att se denna sidan
        </v-alert>
    </v-row>
    <v-card
        elevation="1"
        class="mx-10 my-5"
        v-else
    >
        <v-tabs v-model="current_tab">
            <template v-for="(tab, index) in tabs">
                <v-tab :key="index">{{ tab.name }}</v-tab>
                <v-tab-item :key="index">
                    <v-divider />
                    <component
                        :is="tab.component"
                        :key="index + tab_key"
                    />
                </v-tab-item>
            </template>
        </v-tabs>
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Users from '@/components/admin/Users.vue'
import OrganizationUnits from '@/components/admin/OrganizationUnits.vue'
import AccessGroups from '@/components/admin/AccessGroups.vue'
import { access_mixin } from '@/mixins/services/access_mixin'

export default {
    name: 'Admin',
    props: {},
    mixins: [access_mixin],
    components: { AccessGroups, OrganizationUnits, Users },
    data() {
        return {
            loading: true,
            permission: false,
            tab_key: 0,
            current_tab: 0,
            tabs: [
                {
                    name: 'Användare',
                    component: Users,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['features', 'is_system_user', 'user_extended_data', 'user_extended_data_loaded']),
    },
    watch: {
        features: {
            handler(features) {
                if (features.coach_admin === null) return
                if (features['coach_admin'] || this.is_system_user) {
                    this.permission = true
                }
                this.loading = false
            },
            immediate: true,
            deep: true,
        },
        user_extended_data_loaded: {
            handler(value) {
                if (value) this.set_tabs()
            },
            immediate: true,
        },
        current_tab() {
            this.tab_key++
        },
    },
    methods: {
        set_tabs() {
            if (this.is_system_user || this.is_unit_administrator(this.user_extended_data['user_details'])) {
                this.tabs.push(
                    {
                        name: 'Enheter',
                        component: OrganizationUnits,
                    },
                    {
                        name: 'Supportenheter',
                        component: AccessGroups,
                    }
                )
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
