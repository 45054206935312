<template>
    <v-dialog
        v-model="internal_value"
        width="500"
    >
        <v-card>
            <v-card-title>Nästan tillagd</v-card-title>
            <v-card-text>
                {{ name }} är vald men inte tillagd. Vill du lägga till i listan innan {{ admin_type }} sparas?
            </v-card-text>
            <v-toolbar dense>
                <v-spacer />
                <v-btn @click="$emit('skip')">Hoppa över & spara</v-btn>
                <v-btn
                    @click="$emit('add')"
                    color="primary"
                    class="ml-2"
                >
                    Lägg till & spara
                </v-btn>
            </v-toolbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'SelectedValueDialog',
    props: {
        value: {
            type: Boolean,
        },
        name: {
            type: String,
        },
        admin_type: {
            type: String,
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
