<template>
    <v-dialog
        v-model="internal_value"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-toolbar
            outlined
            dense
        >
            <v-spacer />
            <v-btn
                @click="generate_pdf"
                class="mr-2"
                outlined
                text
            >
                <v-icon>mdi-download</v-icon>
                {{ translate('save') }}
            </v-btn>
            <v-btn
                @click="internal_value = false"
                outlined
                text
            >
                <v-icon>mdi-close</v-icon>
                {{ translate('close') }}
            </v-btn>
        </v-toolbar>
        <div
            class="preview-container"
            :style="{ maxHeight: $vuetify.breakpoint.xs ? 'calc(100vh - 49px)' : 'calc(90vh - 49px)' }"
        >
            <PreviewContent
                ref="preview"
                :data="data"
                :tool="tool"
            />
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PreviewContent from '@/components/user/PreviewContent'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'PreviewDialog',
    props: {
        data: {
            type: Object,
        },
        tool: {
            type: String,
        },
    },
    mixins: [locales_mixin],
    components: { PreviewContent },
    data() {
        return {
            internal_value: true,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        internal_value() {
            this.$emit('close')
        },
    },
    methods: {
        generate_pdf() {
            this.$refs.preview.generate()
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
.preview-container
    overflow-y: auto
    overflow-x: hidden
</style>
