<template>
    <v-card
        elevation="1"
        class="mx-10 my-5"
    >
        <v-card
            v-for="(type, i) in aggregated_statistic_types"
            :key="i"
            color="transparent"
            outlined
        >
            <section class="grid px-4">
                <div ref="placeholder"></div>
                <div></div>
            </section>
            <v-card-title>{{ get_name(type) }}</v-card-title>
            <section class="grid pa-4">
                <div
                    v-for="(statistic, index) in statistics"
                    :key="index"
                >
                    <v-card outlined>
                        <v-card-subtitle>
                            <v-icon class="mr-2">{{ statistic.icon }}</v-icon>
                            {{ statistic.title }}
                        </v-card-subtitle>
                        <v-card
                            height="150"
                            color="transparent"
                            class="pb-4 px-4"
                            outlined
                            :width="width"
                        >
                            <component
                                :is="statistic.component"
                                :labels="labels"
                                :datasets="statistic.datasets(type)"
                            />
                        </v-card>
                    </v-card>
                </div>
            </section>
        </v-card>
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'
import { locales_mixin } from '@/mixins/services/locales_mixin'
import { utils_mixin } from '@/mixins/services/utils_mixin'
import BarChart from '@/components/statistics/BarChart.vue'
import LineChart from '@/components/statistics/LineChart.vue'

export default {
    name: 'Statistics',
    props: {},
    mixins: [api_mixin, date_mixin, locales_mixin, utils_mixin],
    components: { BarChart, LineChart },
    data() {
        return {
            width: 0,
            weeks: [],
            statistics: [
                {
                    datasets: (statistics_key) => this.generate_medals_datasets(statistics_key, 'course'),
                    title: 'Kurser',
                    icon: 'fa-book-open',
                    component: BarChart,
                },
                {
                    datasets: (statistics_key) => this.generate_medals_datasets(statistics_key, 'cv'),
                    title: 'CV',
                    icon: 'fa-address-card',
                    component: BarChart,
                },
                {
                    datasets: (statistics_key) => this.generate_logon_details_datasets(statistics_key),
                    title: 'Inloggningsdetaljer',
                    icon: 'mdi-account-circle-outline',
                    component: BarChart,
                },
                {
                    datasets: (statistics_key) => this.generate_logged_on_datasets(statistics_key),
                    title: 'Inloggningar',
                    icon: 'mdi-login-variant',
                    component: LineChart,
                },
            ],
            statistics_on_organization: {
                url: 'x-u2work--statistics-on-organizations',
                key: 'organization',
                data: [],
                where: {},
            },
            statistics_on_units: {
                url: 'x-u2work--statistics-on-units',
                key: 'unit',
                data: [],
                where: {},
            },
            statistics_on_coaches: {
                url: 'x-u2work--statistics-on-coaches',
                key: 'coach',
                data: [],
                where: {},
            },
            aggregated_statistic_types: [],
            aggregated_statistics: {
                organization: {},
                unit: {},
                coach: {},
            },
        }
    },
    computed: {
        labels() {
            return this.weeks.map((v) => `V.${v.week}`)
        },
        finished_loading() {
            return this.user_extended_data_loaded && !Object.values(this.features).some((x) => x === null)
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'user_extended_data_loaded', 'features']),
    },
    watch: {
        finished_loading: {
            handler(value) {
                if (value) {
                    this.$nextTick(async () => {
                        if (this.features.statistics_organization) {
                            this.statistics_on_organization.where = {
                                organization: this.user_extended_data.organization,
                            }
                            await this.set_params(this.statistics_on_organization, 'organization')
                        }
                        if (this.features.statistics_coach) {
                            this.statistics_on_coaches.where = {
                                coach_manager: this.user_extended_data.user,
                            }
                            await this.set_params(this.statistics_on_coaches, 'coach')
                        }
                    })
                }
            },
            immediate: true,
        },
    },
    methods: {
        initialize_aggregated_statistics() {
            const attributes = [
                'minutes_spent_in_application',
                'clicks',
                'logged_on',
                'nbr_unique_users',
                'course_bronze',
                'course_silver',
                'course_gold',
                'cv_bronze',
                'cv_silver',
                'cv_gold',
                'job_application_bronze',
                'job_application_silver',
                'job_application_gold',
                'new_citizen',
                'recurrent_citizen',
                'new_coach',
                'recurrent_coach',
            ]
            for (const key in this.aggregated_statistics) {
                for (const attribute of attributes) {
                    this.$set(this.aggregated_statistics[key], attribute, [])
                }
            }
        },
        async set_params(data, key) {
            await this.get_statistics(data)
            this.aggregated_statistic_types.push(key)
        },
        get_name(type) {
            switch (type) {
                case 'organization':
                    return `Statistik - ${this.user_extended_data['organization_name']}`
                case 'coach':
                    return `Statistik - ${this.user_extended_data['user_name']}`
                default:
                    return '-'
            }
        },
        async get_statistics(resource) {
            const where = {
                $and: [
                    {
                        week: {
                            $in: Array.from(new Set(this.weeks.map((v) => v.week))),
                        },
                    },
                    {
                        year: {
                            $in: Array.from(new Set(this.weeks.map((v) => v.year))),
                        },
                    },
                ],
            }

            const response = await this.api_get({
                url: resource.url,
                params: {
                    where: { ...where, ...resource.where },
                },
            })
            resource.data.push(...response.data['_items'])
            this.aggregate_statistics(resource)
            this.handle_resize()
        },
        aggregate_statistics(resource) {
            const keys = [
                'activity.minutes_spent_in_application',
                'activity.clicks',
                'activity.logged_on',
                'nbr_unique_users',
                'medals.course_bronze',
                'medals.course_silver',
                'medals.course_gold',
                'medals.cv_bronze',
                'medals.cv_silver',
                'medals.cv_gold',
                'medals.job_application_bronze',
                'medals.job_application_silver',
                'medals.job_application_gold',
                'logon_details.new_citizen',
                'logon_details.recurrent_citizen',
                'logon_details.new_coach',
                'logon_details.recurrent_coach',
            ]

            for (const v of this.weeks) {
                const statistic = resource.data.find((x) => x.week === v.week && x.year === v.year)
                if (!statistic) {
                    for (const key of keys) {
                        const single_key = key.split('.').at(-1)
                        this.aggregated_statistics[resource.key][single_key].push(0)
                    }
                } else {
                    for (const key of keys) {
                        const value = this.deep_get(statistic, key)
                        const single_key = key.split('.').at(-1)
                        this.aggregated_statistics[resource.key][single_key].push(value || 0)
                    }
                }
            }
        },
        generate_medals_datasets(statistics_key, medal_key) {
            const bronze = {
                label: 'Brons',
                backgroundColor: this.$vuetify.theme.currentTheme['bronze'],
                borderColor: this.$vuetify.theme.currentTheme['bronze'],
                data: this.aggregated_statistics[statistics_key][`${medal_key}_bronze`],
                stack: 0,
            }
            const silver = {
                label: 'Silver',
                backgroundColor: this.$vuetify.theme.currentTheme['silver'],
                borderColor: this.$vuetify.theme.currentTheme['silver'],
                data: this.aggregated_statistics[statistics_key][`${medal_key}_silver`],
                stack: 0,
            }
            const gold = {
                label: 'Guld',
                backgroundColor: this.$vuetify.theme.currentTheme['gold'],
                borderColor: this.$vuetify.theme.currentTheme['gold'],
                data: this.aggregated_statistics[statistics_key][`${medal_key}_gold`],
                stack: 0,
            }
            return [bronze, silver, gold]
        },
        generate_logon_details_datasets(statistics_key) {
            const new_citizen = {
                label: 'Ny invånare',
                backgroundColor: this.$vuetify.theme.currentTheme['primary'],
                borderColor: this.$vuetify.theme.currentTheme['primary'],
                data: this.aggregated_statistics[statistics_key]['new_citizen'],
                stack: 0,
            }
            const recurrent_citizen = {
                label: 'Återkommande invånare',
                backgroundColor: this.$vuetify.theme.currentTheme['secondary'],
                borderColor: this.$vuetify.theme.currentTheme['secondary'],
                data: this.aggregated_statistics[statistics_key]['recurrent_citizen'],
                stack: 0,
            }
            const new_coach = {
                label: 'Ny coach',
                backgroundColor: this.$vuetify.theme.currentTheme['tertiary'],
                borderColor: this.$vuetify.theme.currentTheme['tertiary'],
                data: this.aggregated_statistics[statistics_key]['new_coach'],
                stack: 0,
            }
            const recurrent_coach = {
                label: 'Återkommande coach',
                backgroundColor: this.$vuetify.theme.currentTheme['quaternary'],
                borderColor: this.$vuetify.theme.currentTheme['quaternary'],
                data: this.aggregated_statistics[statistics_key]['recurrent_coach'],
                stack: 0,
            }
            return [new_citizen, recurrent_citizen, new_coach, recurrent_coach]
        },
        generate_logged_on_datasets(statistics_key) {
            return [
                {
                    label: 'Inloggningar',
                    backgroundColor: this.$vuetify.theme.currentTheme.primary,
                    borderColor: this.$vuetify.theme.currentTheme.primary,
                    data: this.aggregated_statistics[statistics_key]['logged_on'],
                },
                {
                    label: 'Unika användare',
                    backgroundColor: this.$vuetify.theme.currentTheme.secondary,
                    borderColor: this.$vuetify.theme.currentTheme.secondary,
                    data: this.aggregated_statistics[statistics_key]['nbr_unique_users'],
                },
            ]
        },
        handle_resize() {
            this.$nextTick(() => {
                if (!this.$refs.placeholder) return
                this.width = this.$refs.placeholder[0].clientWidth - 2
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        window.addEventListener('resize', this.handle_resize)
        this.weeks = this.calculate_past_weeks(8)
        this.initialize_aggregated_statistics()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {
        window.removeEventListener('resize', this.handle_resize)
    },
    destroyed() {},
}
</script>
<style scoped lang="sass">
.grid
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 16px

    @media only screen and (max-width: 700px)
        grid-template-columns: 1fr
</style>
