<template>
    <v-card
        color="transparent"
        outlined
    >
        <v-card-title>Kurser</v-card-title>
        <v-card-text v-if="Object.keys(courses).length > 1">
            <ul>
                <li
                    v-for="(course, index) in courses"
                    :key="index"
                >
                    <span
                        @click="scroll(course.id)"
                        class="link"
                    >
                        {{ course.text }}
                    </span>
                </li>
            </ul>
        </v-card-text>

        <v-divider />
        <v-data-table
            v-for="(course, index) in courses"
            :key="index"
            :items="course.items"
            :headers="headers(course)"
            :items-per-page="-1"
            :ref="course.id"
            hide-default-footer
            disable-sort
        >
            <template #item.course="{ item }">
                {{ item['module_info'] }} /
                {{ item['course_info'] }}
            </template>
            <template #item.last_visited="{ item }">
                {{ format_date(item['last_visited']) }}
            </template>
            <template #item.level="{ item }">
                <Trophy :level="item.level" />
            </template>
        </v-data-table>

        <v-card-text v-if="!course_levels.length">
            <v-icon>mdi-information</v-icon>
            Kunde inte hitta några kurser för denna användaren
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { date_mixin } from '@/mixins/services/date_mixin'
import Trophy from '@/components/Trophy.vue'

export default {
    name: 'Courses',
    props: {
        course_levels: {
            type: Array,
        },
    },
    mixins: [date_mixin],
    components: { Trophy },
    data() {
        return {
            courses: {},
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        calculate_course_levels() {
            for (const course of this.course_levels) {
                const { program, program_info } = course
                if (!this.courses[program]) {
                    this.$set(this.courses, program, { text: program_info, id: program, items: [course] })
                } else {
                    this.courses[program].items.push(course)
                }
            }
        },
        headers(course) {
            return [
                {
                    text: course.text,
                    value: 'course',
                },
                {
                    text: 'Senast aktiv',
                    align: 'center',
                    value: 'last_visited',
                },
                {
                    text: 'Nivå',
                    align: 'center',
                    value: 'level',
                },
            ]
        },
        scroll(target) {
            const [element] = this.$refs[target]
            this.$nextTick(() => {
                element.$el.scrollIntoView({ behavior: 'smooth' })
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.calculate_course_levels()
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
::v-deep tbody tr:hover
  cursor: default !important
</style>
