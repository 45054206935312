<template>
    <v-card
        class="pa-4"
        color="transparent"
        outlined
    >
        <Table
            @edit_row="edit_unit"
            resource="organization--units"
            :headers="headers"
            :key="table_key"
        >
            <v-btn
                @click="create_unit"
                class="ml-2"
            >
                <v-icon left>mdi-home-plus</v-icon>
                Ny enhet
            </v-btn>
        </Table>
        <OrganizationUnitDialog
            v-model="show_dialog"
            v-if="show_dialog"
            @trigger_reload="trigger_reload"
            :selected_unit="selected_unit"
        />
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Table from '@/components/admin/Table.vue'
import OrganizationUnitDialog from '@/components/admin/OrganizationUnitDialog.vue'

export default {
    name: 'OrganizationUnits',
    props: {},
    mixins: [],
    components: { OrganizationUnitDialog, Table },
    data() {
        return {
            table_key: 0,
            headers: [
                {
                    text: 'Namn',
                    value: 'name',
                },
            ],
            show_dialog: false,
            selected_unit: {},
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        create_unit() {
            this.selected_unit = {}
            this.show_dialog = true
        },
        edit_unit(unit) {
            this.selected_unit = unit
            this.show_dialog = true
        },
        trigger_reload(return_object) {
            this.table_key++
            if (Object.keys(return_object).length) {
                this.edit_unit(return_object)
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
