import Vue from 'vue'
import VueRouter from 'vue-router'
import Users from '@/views/Users'
import Login from '@/views/Login'
import Statistics from '@/views/Statistics'
import Admin from '@/views/Admin'
import Settings from '@/views/Settings'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/users',
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/users:id?',
        name: 'Users',
        component: Users,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },

    /* PageNotFound Must be last!!!*/
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/*webpackChunkName "page_not_found"*/ '../views/PageNotFound'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
