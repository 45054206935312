<template>
    <v-card
        class="pa-4"
        color="transparent"
        outlined
    >
        <Table
            @edit_row="edit_user"
            resource="users"
            :where="where"
            :headers="headers"
            :key="table_key"
        >
            <v-btn
                v-if="allow_create_and_edit_citizen"
                @click="create_user"
                class="ml-2"
            >
                <v-icon left>mdi-account-plus</v-icon>
                Ny invånare
            </v-btn>
            <v-btn
                v-if="allow_create_and_edit_coach"
                @click="create_coach"
                class="ml-2"
            >
                <v-icon left>mdi-account-plus</v-icon>
                Ny medarbetare
            </v-btn>
        </Table>
        <CitizenDialog
            v-model="show_citizen_dialog"
            v-if="show_citizen_dialog"
            @trigger_reload="trigger_reload"
            :selected_user="selected_user"
            :managers="managers"
            :applications="applications"
        />
        <CoachDialog
            v-model="show_coach_dialog"
            v-if="show_coach_dialog"
            @trigger_reload="trigger_reload"
            :selected_user="selected_user"
            :applications="applications"
        />
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Table from '@/components/admin/Table.vue'
import CitizenDialog from '@/components/admin/CitizenDialog.vue'
import { api_mixin } from '@/mixins/services/api_mixin'
import CoachDialog from '@/components/admin/CoachDialog.vue'
import { access_mixin } from '@/mixins/services/access_mixin'

export default {
    name: 'Users',
    props: {},
    mixins: [access_mixin, api_mixin],
    components: { CoachDialog, CitizenDialog, Table },
    data() {
        return {
            table_key: 0,
            headers: [
                {
                    text: 'Namn',
                    value: 'name',
                },
                {
                    text: 'E-post',
                    value: 'email',
                    sortable: false,
                },
                {
                    text: 'Coach',
                    value: 'manager_name',
                    sortable: false,
                },
                {
                    text: 'Enhet',
                    value: 'organization_unit_name',
                    sortable: false,
                },
            ],
            show_citizen_dialog: false,
            show_coach_dialog: false,
            selected_user: {},
            managers: [],
            applications: [],
        }
    },
    computed: {
        allow_create_and_edit_citizen() {
            return (
                this.is_system_user ||
                this.is_unit_administrator(this.user_extended_data['user_details']) ||
                this.is_coach(this.user_extended_data['user_details'])
            )
        },
        allow_create_and_edit_coach() {
            return (
                this.is_system_user ||
                this.is_unit_administrator(this.user_extended_data['user_details']) ||
                this.is_coach_administrator(this.user_extended_data['user_details'])
            )
        },
        where() {
            const where = {}
            if (!this.allow_create_and_edit_citizen) {
                where['x_u2work.is_coach'] = true
            }
            if (!this.allow_create_and_edit_coach) {
                where.$or = [{ 'x_u2work.is_coach': { $exists: false } }, { 'x_u2work.is_coach': false }]
            }
            return where
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'is_system_user']),
    },
    watch: {},
    methods: {
        create_user() {
            this.selected_user = {}
            this.show_citizen_dialog = true
        },
        create_coach() {
            this.selected_user = {}
            this.show_coach_dialog = true
        },
        edit_user(user) {
            this.selected_user = user
            if (this.is_coach(this.selected_user)) {
                this.show_coach_dialog = true
            } else {
                this.show_citizen_dialog = true
            }
        },
        async get_managers() {
            this.managers = await this.api_get_all_items('users', 'name', {
                'x_u2work.is_coach': true,
                organization: this.user_extended_data.organization,
            })
            if (!this.managers.length) {
                this.managers.push(this.user_extended_data['user_details'])
            }
        },
        async get_applications() {
            this.applications = await this.api_get_all_items('applications')
        },
        trigger_reload() {
            this.table_key += 1
            this.selected_user = {}
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.get_managers()
        this.get_applications()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped lang="sass"></style>
