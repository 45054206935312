<template>
    <v-card outlined>
        <v-data-table
            :items="items"
            :header-props="header_props"
            :headers="headers"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            @click:row="handle_row_click"
            loading-text="Laddar..."
            no-data-text="0 resultat"
            hide-default-footer
        >
            <template #top>
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        sm="8"
                    >
                        <v-card
                            color="transparent"
                            class="d-flex pa-4"
                            outlined
                        >
                            <v-text-field
                                @input="loading = true"
                                v-model="search_text"
                                prepend-inner-icon="mdi-magnify"
                                label="Sök"
                                hide-details
                                outlined
                                dense
                                clearable
                            />
                            <slot />
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-card
                            color="transparent"
                            outlined
                        >
                            <v-card-text
                                class="d-flex align-center"
                                :class="$vuetify.breakpoint.smAndUp ? 'justify-end ' : 'justify-center'"
                            >
                                <v-btn
                                    @click="options.page = 1"
                                    :disabled="options.page === 1"
                                    icon
                                >
                                    <v-icon>mdi-page-first</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="options.page -= 1"
                                    :disabled="options.page === 1"
                                    class="mr-2"
                                    icon
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                {{ options.page }} / {{ pages }}
                                <v-btn
                                    @click="options.page += 1"
                                    :disabled="options.page === pages"
                                    class="ml-2"
                                    icon
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="options.page = pages"
                                    :disabled="options.page === pages"
                                    icon
                                >
                                    <v-icon>mdi-page-last</v-icon>
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider />
            </template>
            <template #item.name="{ item }">
                {{ item['name'] }}
                <v-tooltip
                    v-if="show_icon(item)"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            :color="color(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ icon(item) }}
                        </v-icon>
                    </template>
                    <span>{{ text(item) }}</span>
                </v-tooltip>
            </template>
            <template #item.email="{ item }">
                {{ item['email'] || item['email_oauth'] }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import debounce from 'lodash.debounce'
import { access_mixin } from '@/mixins/services/access_mixin'

export default {
    name: 'Table',
    props: {
        resource: {
            type: String,
        },
        headers: {
            type: Array,
        },
        button_text: {
            type: String,
        },
        button_icon: {
            type: String,
        },
        where: {
            type: Object,
            default: () => {},
        },
    },
    mixins: [access_mixin, api_mixin],
    components: {},
    data() {
        return {
            items: [],
            header_props: {
                sortByText: 'Sortera',
            },
            options: {
                sortBy: ['name'],
                sortDesc: [false],
                itemsPerPage: 20,
                page: 1,
                mustSort: true,
            },
            search_text: '',
            loading: false,
            total: -1,
        }
    },
    computed: {
        pages() {
            const pages = Math.ceil(this.total / this.options.itemsPerPage)
            return pages <= 0 ? 1 : pages
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data']),
    },
    watch: {
        options: {
            handler() {
                this.load_data()
            },
            deep: true,
        },
        search_text: {
            handler: debounce(function () {
                this.load_data()
            }, 500),
        },
    },
    methods: {
        async load_data() {
            this.loading = true
            let where = {}
            if (this.search_text && this.search_text.trim().length > 0) {
                where = {
                    name: {
                        $regex: this.search_text.trim(),
                        $options: 'i',
                    },
                }
            }
            where = { organization: this.user_extended_data.organization, ...where, ...this.where }
            const [sort_by] = this.options.sortBy
            const [sort_desc] = this.options.sortDesc
            const sort = sort_desc ? '-' + sort_by : '' + sort_by
            const max_results = this.options.itemsPerPage
            const page = this.options.page
            const result = await this.api_get({
                url: this.resource,
                params: {
                    where: where,
                    sort: sort,
                    max_results: max_results,
                    page: page,
                },
            })
            this.items = result.data['_items']
            this.total = result.data['_meta']['total']
            if (this.total < max_results) {
                this.options.page = 1
            }
            this.loading = false
        },
        handle_row_click(row) {
            this.$emit('edit_row', row)
        },
        color(item) {
            if (this.is_unit_administrator(item)) {
                return 'primary'
            } else {
                return null
            }
        },
        show_icon(item) {
            const admin = this.is_coach(item) || this.is_coach_administrator(item) || this.is_unit_administrator(item)
            return admin && this.resource === 'users'
        },
        icon(item) {
            if (this.is_coach_administrator(item)) {
                return 'mdi-shield-crown'
            } else {
                return 'mdi-shield-crown-outline'
            }
        },
        text(item) {
            if (this.is_unit_administrator(item)) {
                return 'Enhetsadministratör'
            } else if (this.is_coach_administrator(item)) {
                return 'Coachadministratör'
            } else {
                return 'Coach'
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.load_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
::v-deep tbody tr:hover
    cursor: pointer
</style>
