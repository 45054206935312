<template>
    <v-card
        tile
        class="pa-4"
    >
        <section
            ref="wrapper"
            class="main-wrapper"
        >
            <section
                class="wrapper"
                :style="zoom_style"
            >
                <section
                    :class="{ disabled: editing_array }"
                    class="pdf"
                    ref="pdf"
                >
                    <section
                        v-if="full_name"
                        class="pdf-item text-center"
                    >
                        <span class="text-24">{{ full_name }}</span>
                        <section class="details">
                            <div v-if="phone">
                                <i class="mdi mdi-phone" />
                                {{ phone }}
                            </div>
                            <div v-if="email">
                                <i class="mdi mdi-email" />
                                {{ email }}
                            </div>
                            <div v-if="driver_license">
                                <i class="mdi mdi-card-account-details" />
                                {{ driver_license }}
                            </div>
                        </section>
                        <hr />
                    </section>

                    <section
                        v-if="summary.length"
                        class="pdf-item"
                    >
                        <span class="text-18 bold"> {{ translate('summary') }} </span>
                        <div
                            v-for="(text, index) in summary"
                            :key="`${index}_summary`"
                        >
                            <span
                                class="text"
                                v-if="text === '&#8205;'"
                            >
                                &#8205;
                            </span>
                            <span v-else>{{ text }}</span>
                        </div>
                    </section>

                    <section
                        v-if="work_summary.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(data, index) in work_summary"
                            :key="`${index}_work_summary`"
                            :class="{ current: apply_current_class('work_summary', index) }"
                            class="array"
                        >
                            <span
                                v-if="index === 0"
                                class="text-18 bold"
                            >
                                {{ translate('work_summary') }}
                            </span>
                            <section>
                                <div class="box-2">
                                    <div>
                                        <span class="bold">{{ data['title'] }}</span>
                                        <span v-if="data['employer']">{{ data['employer'] }}</span>
                                    </div>
                                    <div>
                                        <span
                                            v-if="data['start_date']"
                                            class="bold"
                                        >
                                            {{ format_time_span(data['start_date'], data['end_date']) }}
                                        </span>
                                        <span v-if="data['city']">{{ data['city'] }}</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(text, index) in data['description']"
                                    :key="`${index}_work_summary_description`"
                                >
                                    <span
                                        class="text"
                                        v-if="text === '&#8205;'"
                                    >
                                        &#8205;
                                    </span>
                                    <span v-else>{{ text }}</span>
                                </div>
                                <div
                                    v-for="(text, index) in data['achievement']"
                                    :key="`${index}_work_summary_achievement`"
                                >
                                    <span
                                        class="text"
                                        v-if="text === '&#8205;'"
                                    >
                                        &#8205;
                                    </span>
                                    <span v-else>{{ text }}</span>
                                </div>
                            </section>
                        </div>
                    </section>

                    <section
                        v-if="educations.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(data, index) in educations"
                            :key="`${index}_educations`"
                            :class="{ current: apply_current_class('educations', index) }"
                            class="array"
                        >
                            <span
                                v-if="index === 0"
                                class="text-18 bold"
                            >
                                {{ translate('educations') }}
                            </span>
                            <section>
                                <div class="box-2">
                                    <div>
                                        <span class="bold">{{ data['school'] }}</span>
                                        <span v-if="data['degree']">{{ data['degree'] }}</span>
                                    </div>
                                    <div>
                                        <span
                                            v-if="data['start_date']"
                                            class="bold"
                                        >
                                            {{ format_time_span(data['start_date'], data['end_date']) }}
                                        </span>
                                        <span v-if="data['city']">{{ data['city'] }}</span>
                                    </div>
                                </div>
                                <div
                                    v-for="(text, index) in data['description']"
                                    :key="`${index}_educations_description`"
                                >
                                    <span
                                        class="text"
                                        v-if="text === '&#8205;'"
                                    >
                                        &#8205;
                                    </span>
                                    <span v-else>{{ text }}</span>
                                </div>
                            </section>
                        </div>
                    </section>

                    <section
                        v-if="languages.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(data, index) in languages"
                            :key="`${index}_languages`"
                            :class="{ current: apply_current_class('languages', index) }"
                            class="array"
                        >
                            <span
                                v-if="index === 0"
                                class="text-18 bold"
                            >
                                {{ translate('languages') }}
                            </span>
                            <div class="title-level">
                                <span class="bold">{{ data['locale'] }}</span>
                                <span>{{ data['level'] }}/5</span>
                            </div>
                            <span
                                v-if="data['description']"
                                class="text"
                            >
                                {{ data['description'] }}
                            </span>
                        </div>
                    </section>

                    <section
                        v-if="skills.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(data, index) in skills"
                            :key="`${index}_skills`"
                            :class="{ current: apply_current_class('skills', index) }"
                            class="array"
                        >
                            <span
                                v-if="index === 0"
                                class="text-18 bold"
                            >
                                {{ translate('skills') }}
                            </span>
                            <div class="title-level">
                                <span class="bold">{{ data['skill'] }}</span>
                                <span>{{ data['level'] }}/5</span>
                            </div>
                            <span
                                v-if="data['description']"
                                class="text"
                            >
                                {{ data['description'] }}
                            </span>
                        </div>
                    </section>

                    <section
                        v-if="extra_sections.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(data, index) in extra_sections"
                            :key="`${index}_extra_sections`"
                            :class="{ current: apply_current_class('extra_sections', index) }"
                            class="array"
                        >
                            <span class="text-18 bold"> {{ data['title'] }} </span>
                            <span
                                v-if="data['paragraph']"
                                class="text"
                            >
                                {{ data['paragraph'] }}
                            </span>
                            <div v-if="data['section_items']">
                                <div
                                    v-for="(extra, index) in data['section_items']"
                                    :key="`${index}_extra_sections_section_items`"
                                    class="box-2"
                                >
                                    <div>
                                        <span class="bold">{{ extra['details'] }}</span>
                                        <span v-if="extra['description']">{{ extra['description'] }}</span>
                                    </div>
                                    <div>
                                        <span
                                            v-if="extra['start_date']"
                                            class="bold"
                                        >
                                            {{ format_time_span(extra['start_date'], extra['end_date']) }}
                                        </span>
                                        <span v-if="extra['city']">{{ extra['city'] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <span
                        v-if="tool === 'application'"
                        class="text-18 bold"
                    >
                        {{ translate('personal_letter') }}
                    </span>
                    <section
                        v-if="attention.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(text, index) in attention"
                            :key="`${index}_attention`"
                        >
                            <span
                                class="text"
                                v-if="text === '&#8205;'"
                            >
                                &#8205;
                            </span>
                            <span v-else>{{ text }}</span>
                        </div>
                    </section>

                    <section
                        v-if="why_apply_for_this_job.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(text, index) in why_apply_for_this_job"
                            :key="`${index}_why_apply_for_this_job`"
                        >
                            <span
                                class="text"
                                v-if="text === '&#8205;'"
                            >
                                &#8205;
                            </span>
                            <span v-else>{{ text }}</span>
                        </div>
                    </section>

                    <section
                        v-if="why_do_you_fit_this_job.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(text, index) in why_do_you_fit_this_job"
                            :key="`${index}_why_do_you_fit_this_job`"
                        >
                            <span
                                class="text"
                                v-if="text === '&#8205;'"
                            >
                                &#8205;
                            </span>
                            <span v-else>{{ text }}</span>
                        </div>
                    </section>

                    <section
                        v-if="personal_introduction.length"
                        class="pdf-item"
                    >
                        <div
                            v-for="(text, index) in personal_introduction"
                            :key="`${index}_personal_introduction`"
                        >
                            <span
                                class="text"
                                v-if="text === '&#8205;'"
                            >
                                &#8205;
                            </span>
                            <span v-else>{{ text }}</span>
                        </div>
                    </section>
                </section>
            </section>
        </section>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { date_mixin } from '@/mixins/services/date_mixin'
import { locales_mixin } from '@/mixins/services/locales_mixin'
import html2pdf from 'html2pdf.js'

export default {
    name: 'PreviewContent',
    props: {
        data: {
            type: Object,
        },
        lazy_value: {
            type: Object,
            default: () => {},
        },
        editing_index: {
            type: Number,
        },
        content: {
            type: String,
        },
        disabled_lazy_value_watcher: {
            type: Boolean,
        },
        tool: {
            type: String,
        },
    },
    mixins: [date_mixin, locales_mixin],
    components: {},
    data() {
        return {
            zoom: 1,
            min_height: 1123,
            lazy_array: [],
            last_index: 0,
            editing_array: false,
        }
    },
    computed: {
        zoom_style() {
            return {
                transform: `scale(${this.zoom.toFixed(1)})`,
                'transform-origin': 'top',
                'min-height': `${this.min_height}px`,
            }
        },
        full_name() {
            const first_name = this.data?.details?.['first_name'] ?? ''
            const last_name = this.data?.details?.['last_name'] ?? ''
            if (first_name && last_name) {
                return `${first_name.trim()} ${last_name.trim()}`
            } else {
                return first_name.trim() + last_name.trim()
            }
        },
        phone() {
            const phone = this.data?.details?.['phone'] ?? ''
            return phone.trim()
        },
        email() {
            const email = this.data?.details?.['email'] ?? ''
            return email.trim()
        },
        driver_license() {
            const driver_license = this.data?.details?.['driver_license']
            const driver_license_details = this.data?.details?.['driver_license_details']
            if (driver_license && driver_license_details) {
                return `${this.translate('driver_license')} (${driver_license_details.trim()})`
            } else if (driver_license) {
                return this.translate('driver_license')
            }
            return ''
        },
        summary() {
            if (!this.data?.summary) return []
            const text = this.data.summary.split('\n')
            return text.map((t) => {
                if (!t.length) {
                    return '&#8205;'
                } else {
                    return t
                }
            })
        },
        work_summary() {
            const lazy = this.content && this.content === 'work_summary'
            const array = lazy ? this.lazy_array : this.data?.work_summary || []
            return array
                .map((v) => {
                    v = { ...v }
                    if (v['start_date']) v['start_date'] = this.format_date(v['start_date'])
                    if (v['end_date']) v['end_date'] = this.format_date(v['end_date'])
                    if (v['description']) {
                        const text = v['description'].split('\n')
                        v['description'] = text.map((t) => {
                            if (!t.length) {
                                return '&#8205;'
                            } else {
                                return t
                            }
                        })
                    }
                    if (v['achievement']) {
                        const text = v['achievement'].split('\n')
                        v['achievement'] = text.map((t) => {
                            if (!t.length) {
                                return '&#8205;'
                            } else {
                                return t
                            }
                        })
                    }
                    if (v['title']) {
                        return v
                    }
                })
                .filter((v) => v !== undefined)
        },
        educations() {
            const lazy = this.content && this.content === 'educations'
            const array = lazy ? this.lazy_array : this.data?.educations || []
            return array
                .map((v) => {
                    v = { ...v }
                    if (v['start_date']) v['start_date'] = this.format_date(v['start_date'])
                    if (v['end_date']) v['end_date'] = this.format_date(v['end_date'])
                    if (v['description']) {
                        const text = v['description'].split('\n')
                        v['description'] = text.map((t) => {
                            if (!t.length) {
                                return '&#8205;'
                            } else {
                                return t
                            }
                        })
                    }
                    if (v['school']) {
                        return v
                    }
                })
                .filter((v) => v !== undefined)
        },
        languages() {
            const lazy = this.content && this.content === 'languages'
            const array = lazy ? this.lazy_array : this.data?.languages || []
            return array
                .map((v) => {
                    if (v['locale']) {
                        return v
                    }
                })
                .filter((v) => v !== undefined)
        },
        skills() {
            const lazy = this.content && this.content === 'skills'
            const array = lazy ? this.lazy_array : this.data?.skills || []
            return array
                .map((v) => {
                    if (v['skill']) {
                        return v
                    }
                })
                .filter((v) => v !== undefined)
        },
        extra_sections() {
            const lazy = this.content && this.content === 'extra_sections'
            const array = lazy ? this.lazy_array : this.data?.extra_sections || []
            return array
                .map((v) => {
                    v = { ...v }
                    if (v['title']) {
                        if (v['section_items']) {
                            v['section_items'] = v['section_items']
                                .map((e) => {
                                    e = { ...e }
                                    if (e['start_date']) e['start_date'] = this.format_date(e['start_date'])
                                    if (e['end_date']) e['end_date'] = this.format_date(e['end_date'])
                                    if (e['details']) {
                                        return e
                                    }
                                })
                                .filter((e) => e !== undefined)
                        } else {
                            v['section_items'] = []
                        }
                        return v
                    }
                })
                .filter((v) => v !== undefined)
        },
        attention() {
            if (!this.data?.attention) return []
            const text = this.data.attention.split('\n')
            return text.map((t) => {
                if (!t.length) {
                    return '&#8205;'
                } else {
                    return t
                }
            })
        },
        why_apply_for_this_job() {
            if (!this.data?.why_apply_for_this_job) return []
            const text = this.data.why_apply_for_this_job.split('\n')
            return text.map((t) => {
                if (!t.length) {
                    return '&#8205;'
                } else {
                    return t
                }
            })
        },
        why_do_you_fit_this_job() {
            if (!this.data?.why_do_you_fit_this_job) return []
            const text = this.data.why_do_you_fit_this_job.split('\n')
            return text.map((t) => {
                if (!t.length) {
                    return '&#8205;'
                } else {
                    return t
                }
            })
        },
        personal_introduction() {
            if (!this.data?.personal_introduction) return []
            const text = this.data.personal_introduction.split('\n')
            return text.map((t) => {
                if (!t.length) {
                    return '&#8205;'
                } else {
                    return t
                }
            })
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        lazy_value: {
            handler(value) {
                if (!this.disabled_lazy_value_watcher) this.editing_array = true
                if (this.editing_index !== null) {
                    this.$set(this.lazy_array, this.editing_index, value)
                } else {
                    this.$set(this.lazy_array, this.last_index, value)
                }
            },
            deep: true,
        },
    },
    methods: {
        format_time_span(start_date, end_date) {
            if (start_date && end_date) {
                return `${start_date} - ${end_date}`
            } else {
                return `${start_date} - ${this.translate('ongoing')}`
            }
        },
        apply_current_class(content, index) {
            if (content !== this.content) return false
            return this.editing_array && (index === this.editing_index || index === this.last_index)
        },
        handle_resize() {
            this.$nextTick(() => {
                const width = this.$refs.wrapper.clientWidth
                if (width >= 794) {
                    this.zoom = 1
                    this.min_height = 1123
                } else {
                    this.zoom = width / 794
                    if (this.zoom <= 0.75) this.zoom = 0.75
                    this.min_height = Math.ceil(1123 * this.zoom)
                }
            })
        },
        generate() {
            const element = this.$refs.pdf
            const name = this.data.name || this.data.application_name
            const options = {
                margin: [15, 10, 15, 10],
                filename: `${name}.pdf`,
                pagebreak: {
                    image: { type: 'jpeg', quality: 1 },
                    mode: 'css',
                    avoid: ['.array'],
                },
            }
            html2pdf(element, options)
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        if (this.content) {
            this.lazy_array = [...this.data?.[this.content]] || []
            this.last_index = this.lazy_array.length
        }
    },
    beforeMount() {},
    mounted() {
        this.handle_resize()
        window.addEventListener('resize', this.handle_resize)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {
        window.removeEventListener('resize', this.handle_resize)
    },
    destroyed() {},
}
</script>
<style scoped lang="sass">
section.disabled
    transition: color 1s ease
    color: rgba(0, 0, 0, 0.47)

section.disabled div.current
    color: rgba(0, 0, 0, 0.87)

.pdf
    font-family: 'Roboto', 'sans-serif' !important

.pdf-item:not(:first-child)
    padding-bottom: 36px

hr
    color: #eee
    margin-bottom: 18px

span
    display: block

span.text
    padding-bottom: 6px

.title-level
    display: flex
    margin-bottom: 2px

    span.bold
        margin-right: 6px

span.bold
    font-weight: bold

span.text-18
    font-size: 18px
    padding-bottom: 12px

span.text-24
    font-size: 24px
    padding-bottom: 12px

section.text-center
    text-align: center

section.details
    display: flex
    justify-content: center
    gap: 12px
    padding-bottom: 12px

div.box-2
    display: flex
    justify-content: space-between
    margin-bottom: 12px

    div
        display: flex
        flex-direction: column

    div:nth-child(1)
        align-items: start

    div:nth-child(2)
        align-items: end

div.array
    margin-bottom: 12px


.main-wrapper
    display: flex
    justify-content: center

.wrapper
    width: 794px
</style>
