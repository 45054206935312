<template>
    <BarChartGenerator
        :data="internal_value"
        :options="options"
    />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Bar as BarChartGenerator } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale)

export default {
    name: 'BarChart',
    props: {
        datasets: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
        },
    },
    mixins: [],
    components: { BarChartGenerator },
    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        suggestedMax: 5,
                        beginAtZero: true,
                    },
                },
            },
        }
    },
    computed: {
        internal_value() {
            return {
                labels: this.labels,
                datasets: this.datasets,
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
