<template>
    <v-card
        elevation="1"
        class="mx-10 my-5"
    >
        <v-data-table
            v-if="!selected_user"
            :items="users"
            :header-props="header_props"
            :headers="headers"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            @click:row="handle_user"
            loading-text="Laddar..."
            no-data-text="0 resultat"
            hide-default-footer
        >
            <template #top>
                <v-row
                    no-gutters
                    class="pa-4"
                >
                    <v-col
                        cols="12"
                        sm="3"
                    >
                        <v-text-field
                            @input="loading = true"
                            v-model="search_text"
                            prepend-inner-icon="mdi-magnify"
                            label="Sök"
                            hide-details
                            outlined
                            dense
                            clearable
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="3"
                    >
                        <v-autocomplete
                            v-if="units.length"
                            :class="{ 'mt-2': $vuetify.breakpoint.xs, 'ml-2': !$vuetify.breakpoint.xs }"
                            v-model="selected_unit"
                            :items="units"
                            label="Enhet"
                            item-text="name"
                            item-value="_id"
                            hide-details
                            outlined
                            dense
                            clearable
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-card-text
                            class="d-flex align-center pa-0"
                            :class="$vuetify.breakpoint.smAndUp ? 'justify-end ' : 'justify-center'"
                        >
                            <v-btn
                                @click="options.page = 1"
                                :disabled="options.page === 1"
                                icon
                            >
                                <v-icon>mdi-page-first</v-icon>
                            </v-btn>
                            <v-btn
                                @click="options.page -= 1"
                                :disabled="options.page === 1"
                                class="mr-2"
                                icon
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            {{ options.page }} / {{ pages }}
                            <v-btn
                                @click="options.page += 1"
                                :disabled="options.page === pages"
                                class="ml-2"
                                icon
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn
                                @click="options.page = pages"
                                :disabled="options.page === pages"
                                icon
                            >
                                <v-icon>mdi-page-last</v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
            </template>

            <template #item.user_name="{ item }">
                <v-list-item
                    two-line
                    class="pa-0"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item['user_name'] }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item['user_email'] }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <template #item.latest_activity="{ item }">
                <div style="white-space: nowrap">{{ get_latest_activity(item) }}</div>
            </template>

            <template #item.course_levels="{ item }">
                <span
                    v-for="program in item['program_completions']"
                    :key="program['program_info']"
                    class="text-no-wrap"
                    style="display: block"
                >
                    <v-progress-circular
                        :value="program['percent_completed']"
                        :rotate="-90"
                        :size="20"
                        class="mr-2"
                        color="primary"
                        small
                    />
                    {{ program['program_info'] }}: {{ program['percent_completed'] }}%
                </span>
            </template>

            <template #item.cv_level="{ item }">
                <Trophy :level="item['cv_level']"/>
            </template>

            <template #item.job_application_level="{ item }">
                <Trophy :level="item['job_application_level']"/>
            </template>

            <template #item.interview_level="{ item }">
                <Trophy :level="item['interview_level']"/>
            </template>

            <template #item.best_score_interview_game="{ item }">
                {{ item['best_score_interview_game'] || '-' }}
            </template>
            <template #item.actions="{ item }">
                <Actions :item="item"/>
            </template>
            <template #footer>
                <v-divider/>
                <div class="pa-4 d-flex justify-center align-center">
                    <v-btn
                        @click="show_inactive_users_dialog = true"
                        text
                        x-small
                    >
                        Visa användare som aldrig loggat in
                    </v-btn>
                </div>
            </template>
        </v-data-table>

        <User
            :user="user"
            :loading="loading"
            v-else
        />
        <InactiveUsersDialog
            :all_user_ids="all_user_ids"
            :search_text="search_text"
            :selected_unit="selected_unit"
            v-model="show_inactive_users_dialog"
        />
    </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {date_mixin} from '@/mixins/services/date_mixin'
import {locales_mixin} from '@/mixins/services/locales_mixin'
import debounce from 'lodash.debounce'
import User from '@/components/user'
import Trophy from '@/components/Trophy.vue'
import Actions from '@/components/user/Actions.vue'
import {utils_mixin} from '@/mixins/services/utils_mixin'
import InactiveUsersDialog from '@/components/user/InactiveUsersDialog.vue'

export default {
    name: 'Users',
    props: {},
    mixins: [api_mixin, date_mixin, locales_mixin, utils_mixin],
    components: {InactiveUsersDialog, Actions, Trophy, User},
    data() {
        return {
            users: [],
            all_user_ids: [],
            user: {},
            total: -1,
            loading: false,
            header_props: {
                sortByText: 'Sortera',
            },
            headers: [
                {
                    text: 'Namn (e-post)',
                    value: 'user_name',
                },
                {
                    text: 'Senast aktiv',
                    value: 'latest_activity',
                },
                {
                    text: 'Genomförande',
                    value: 'course_levels',
                    sortable: false,
                },
                {
                    text: 'CV',
                    value: 'cv_level',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Ansökan',
                    value: 'job_application_level',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Intervjuspel',
                    value: 'interview_level',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Antal spel',
                    value: 'number_interview_games',
                    sortable: false,
                },
                {
                    text: 'Bästa poäng',
                    value: 'best_score_interview_game',
                    sortable: false,
                },
                // {
                //     text: 'Åtgärder',
                //     value: 'actions',
                //     align: 'center',
                //     sortable: false,
                // },
            ],
            search_text: '',
            options: {
                sortBy: ['latest_activity'],
                sortDesc: [true],
                itemsPerPage: 10,
                page: 1,
                mustSort: true,
            },
            selected_unit: '',
            show_inactive_users_dialog: false,
        }
    },
    computed: {
        pages() {
            const pages = Math.ceil(this.total / this.options.itemsPerPage)
            return pages <= 0 ? 1 : pages
        },
        selected_user() {
            return this.$route.query.id
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'units', 'user_extended_data_loaded']),
    },
    watch: {
        options: {
            handler() {
                this.load_users()
            },
            deep: true,
        },
        user_extended_data_loaded() {
            this.load_users()
            this.get_all_user_ids()
        },
        search_text: {
            handler: debounce(function () {
                this.load_users()
            }, 500),
        },
        selected_user: {
            async handler(value) {
                this.user = {}
                if (!value) return

                this.loading = true
                if (!this.users.find((v) => v['user'] === value)) {
                    const result = await this.api_get({
                        url: 'x-u2work--progress-users',
                        params: {
                            where: {
                                user: value,
                            },
                        },
                    })
                    if (result && result.data) {
                        this.user = result.data['_items'][0]
                    }
                } else {
                    this.user = this.users.find((v) => v['user'] === this.selected_user)
                }
                this.loading = false
            },
            immediate: true,
        },
        selected_unit() {
            this.load_users()
        },
        units() {
            const {skills_group} = this.$route.query
            if (skills_group) {
                const unit = this.units.find((unit) => {
                    const {external_id} = unit
                    if (external_id && Object.keys(external_id).length === 2) {
                        return external_id.id === skills_group && external_id.system === 'skills'
                    }
                })
                if (unit) this.selected_unit = unit['_id']
            }
        },
    },
    methods: {
        async load_users() {
            if (!this.user_extended_data_loaded) return

            this.loading = true
            const where = {}

            const {organization} = this.user_extended_data
            where.user_organization = organization
            // where.user_organization_unit = { $ne: this.coach_unit() }

            const or = []
            if (this.search_text && this.search_text.trim().length > 0) {
                or.push(
                    {
                        user_name: {
                            $regex: this.search_text.trim(),
                            $options: 'i',
                        },
                    },
                    {
                        user_email: {
                            $regex: this.search_text.trim(),
                            $options: 'i',
                        },
                    }
                )
            }
            if (this.selected_unit) {
                or.push(
                    {
                        user_organization_unit: this.selected_unit,
                    },
                    {
                        'organization_units.unit': this.selected_unit,
                    }
                )
            }
            if (or.length) where.$or = or

            const [sort_by] = this.options.sortBy
            const [sort_desc] = this.options.sortDesc
            const sort = sort_desc ? '-' + sort_by : '' + sort_by
            const max_results = this.options.itemsPerPage
            const page = this.options.page
            const result = await this.api_get({
                url: 'x-u2work--progress-users',
                params: {
                    where: where,
                    sort: sort,
                    max_results: max_results,
                    page: page,
                },
            })
            this.users = result.data['_items']
            this.total = result.data['_meta']['total']
            if (this.total < max_results) {
                this.options.page = 1
            }
            this.loading = false
        },
        async get_all_user_ids() {
            const {organization} = this.user_extended_data
            const items = await this.api_get_all_items(
                'x-u2work--progress-users',
                '',
                {
                    user_organization: organization,
                },
                {
                    user: 1,
                }
            )
            this.all_user_ids = items.map((v) => v['user'])
        },
        get_latest_activity(item) {
            return this.format_date(item['latest_activity'])
        },
        handle_user(user) {
            this.$router.push({query: {id: user['user']}})
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
        const {search} = this.$route.query
        if (search) {
            this.search_text = search
            this.$router.replace({query: null})
        }
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
::v-deep tbody tr:hover
    cursor: pointer

::v-deep th
    white-space: nowrap
</style>
