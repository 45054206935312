<template>
    <v-row
        v-if="loading"
        class="mt-12"
        justify="center"
    >
        <v-progress-circular
            color="primary"
            indeterminate
        />
    </v-row>
    <v-card
        elevation="1"
        class="mx-10 my-5"
        v-else
    >
        <v-card-title>Inställningar</v-card-title>
        <v-card-text>
            <v-switch
                v-for="(item, index) in nudging"
                :key="index"
                @change="update_nudging_settings(item)"
                :label="item.label"
                :disabled="item.loading"
                :loading="item.loading"
                v-model="item.value"
                dense
            />
        </v-card-text>
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'

export default {
    name: 'Settings',
    props: {},
    mixins: [api_mixin],
    components: {},
    data() {
        return {
            loading: true,
            nudging: [
                {
                    label: 'Påminnelser (e-post)',
                    attribute: 'nudging_enabled',
                    value: null,
                    loading: true,
                },
                {
                    label: 'Påminnelser för coacher (e-post)',
                    attribute: 'coach_nudging_enabled',
                    value: null,
                    loading: true,
                },
            ],
            nudging_id: null,
            nudging_etag: null,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'user_extended_data_loaded']),
    },
    watch: {
        user_extended_data_loaded: {
            handler(value) {
                if (value) {
                    this.loading = false
                    this.load_nudging_settings()
                }
            },
            immediate: true,
        },
    },
    methods: {
        async load_nudging_settings() {
            const response = await this.api_get({
                url: 'x-u2work--nudging-settings',
                params: {
                    where: {
                        user: this.user_extended_data.user,
                    },
                },
            })
            if (response.status === 200) {
                const items = response.data['_items']
                if (items.length) {
                    const [item] = items
                    this.nudging_id = item['_id']
                    this.nudging_etag = item['_etag']
                    for (const x of this.nudging) {
                        const attribute = x['attribute']
                        x['value'] = item[attribute]
                        x['loading'] = false
                    }
                } else {
                    await this.create_nudging_settings()
                }
            }
        },
        async create_nudging_settings() {
            const data = {
                nudging_enabled: true,
                coach_nudging_enabled: false,
            }
            const response = await this.api_post({
                url: 'x-u2work--nudging-settings',
                data: {
                    user: this.user_extended_data.user,
                    ...data,
                },
            })
            if (response.status === 201) {
                for (const x of this.nudging) {
                    const attribute = x['attribute']
                    x['value'] = data[attribute]
                    x['loading'] = false
                }
            }
            await this.load_nudging_settings() // reload for correct _etag
        },
        async update_nudging_settings(item) {
            item.loading = true
            const response = await this.api_patch({
                url: `x-u2work--nudging-settings/${this.nudging_id}`,
                data: { [item.attribute]: item.value },
                if_match: this.nudging_etag,
            })
            if (response.status === 200) {
                this.nudging_id = response.data['_id']
                this.nudging_etag = response.data['_etag']
            }
            item.loading = false
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
