import { render, staticRenderFns } from "./InactiveUsersDialog.vue?vue&type=template&id=700003ad&scoped=true"
import script from "./InactiveUsersDialog.vue?vue&type=script&lang=js"
export * from "./InactiveUsersDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700003ad",
  null
  
)

export default component.exports