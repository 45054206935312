<template>
    <v-app-bar
        ref="app_bar"
        :scroll-threshold="50"
        color="primary accent-4"
        hide-on-scroll
        app
        dark
    >
        <v-app-bar-nav-icon @click="$emit('navigation_drawer')" />
        <v-toolbar-title class="align-center">
            <v-img
                alt="Coach"
                src="../../assets/logo_white.png"
                :width="$vuetify.breakpoint.mdAndUp ? '120px' : '100px'"
                @click="go_to_home"
                style="cursor: pointer"
            />
        </v-toolbar-title>
    </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'TheHeader',
    props: {
        value: {
            type: [String, Object, Number, Boolean, Array],
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        go_to_home() {
            if (this.$route.name !== 'Users' || this.$route.query.id) {
                this.$router.push({ name: 'Users' })
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
