<template>
    <div
        v-if="loading"
        class="pa-4 d-flex justify-center"
    >
        <v-progress-circular
            color="primary"
            indeterminate
        />
    </div>
    <div v-else-if="error">
        <v-card-text class="red--text d-flex justify-center">
            <v-icon
                color="error"
                class="mr-2"
            >
                mdi-alert
            </v-icon>
            Användaren hittades inte
        </v-card-text>
    </div>
    <div v-else>
        <v-row
            class="d-flex align-center"
            no-gutters
        >
            <v-btn
                @click="$router.push({ name: 'Users' })"
                class="ml-4"
                large
                icon
            >
                <v-icon large>mdi-arrow-left</v-icon>
            </v-btn>
            <div>
                <v-card-title>{{ user['user_name'] }}</v-card-title>
                <v-card-subtitle>Senast aktiv: {{ latest_activity(user['latest_activity']) }}</v-card-subtitle>
            </div>
        </v-row>
        <Statistics
            :labels="labels"
            :minutes_spent_in_application="minutes_spent_in_application"
            :clicks="clicks"
            :logged_on="logged_on"
        />
        <v-divider />
        <v-tabs
            :vertical="$vuetify.breakpoint.mdAndUp"
            :centered="$vuetify.breakpoint.smAndDown"
            icons-and-text
        >
            <v-tab>
                Kurser
                <v-icon>fa-book-open</v-icon>
            </v-tab>
            <v-tab>
                {{ $t('interview_game') }}
                <Trophy
                    :custom_css="trophy_right_css"
                    :level="this.user['interview_level']"
                />
                <v-icon>fa-comments</v-icon>
            </v-tab>
            <v-tab v-if="has_access_to_cv">
                CV
                <Trophy
                    :custom_css="trophy_left_css"
                    :level="this.user['cv_level']"
                />
                <v-icon>fa-address-card</v-icon>
            </v-tab>
            <v-tab v-if="has_access_to_job_application">
                <Trophy
                    :custom_css="trophy_right_css"
                    :level="this.user['job_application_level']"
                />
                Jobbansökan
                <v-icon>fa-user-graduate</v-icon>
            </v-tab>
            <v-tab>
                Inställningar
                <v-icon>mdi-cogs</v-icon>
            </v-tab>

            <v-tab-item>
                <Courses :course_levels="user['course_levels'] || []" />
            </v-tab-item>
            <v-tab-item>
                <Interview
                    :number_interview_games="user['number_interview_games'] || 0"
                    :best_score_interview_game="user['best_score_interview_game'] || 0"
                    :user_id="user['user']"
                />
            </v-tab-item>
            <v-tab-item v-if="has_access_to_cv">
                <Tools
                    :user_id="user['user']"
                    tool="cv"
                />
            </v-tab-item>
            <v-tab-item v-if="has_access_to_job_application">
                <Tools
                    :user_id="user['user']"
                    tool="application"
                />
            </v-tab-item>
            <v-tab-item>
                <Settings :user_id="user['user']" />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { differenceInDays, isSameDay, sub } from 'date-fns'
import Statistics from '@/components/statistics'
import { date_mixin } from '@/mixins/services/date_mixin'
import Courses from '@/components/user/Courses.vue'
import Interview from '@/components/user/Interview.vue'
import Tools from '@/components/user/Tools.vue'
import Trophy from '@/components/Trophy.vue'
import Settings from '@/components/user/Settings.vue'

export default {
    name: 'User',
    props: {
        user: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
    },
    mixins: [api_mixin, date_mixin],
    components: { Settings, Trophy, Tools, Interview, Courses, Statistics },
    data() {
        return {
            weeks: [],
            statistics: [],
            minutes_spent_in_application: [],
            clicks: [],
            logged_on: [],
            error: false,
            has_access_to_cv: false,
            has_access_to_job_application: false,
        }
    },
    computed: {
        labels() {
            return this.weeks.map((v) => `V.${v.week}`)
        },
        trophy_right_css() {
            return {
                position: 'absolute',
                left: '70px',
                top: '5px',
                transform: 'rotate(-15deg) scale(0.8)',
                'z-index': 999,
                'pointer-events': 'none',
            }
        },
        trophy_left_css() {
            return {
                position: 'absolute',
                left: '40px',
                top: '5px',
                transform: 'rotate(15deg) scale(0.8)',
                'z-index': 999,
                'pointer-events': 'none',
            }
        },
        ...mapGetters([]),
        ...mapState(['features', 'is_system_user']),
    },
    watch: {
        loading(value) {
            if (!value && !this.user) {
                this.error = true
            }
        },
        user: {
            handler(value) {
                if (Object.keys(value).length) {
                    this.$nextTick(() => {
                        this.get_statistics()
                    })
                }
            },
            immediate: true,
        },
        features: {
            handler(features) {
                if (features.cv || this.is_system_user) this.has_access_to_cv = true
                if (features.job_application || this.is_system_user) this.has_access_to_job_application = true
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        latest_activity(latest_activity) {
            const today = new Date()
            const yesterday = sub(today, { days: 1 })

            if (isSameDay(new Date(latest_activity), today)) {
                return `Idag ${this.format_time(latest_activity)}`
            } else if (isSameDay(new Date(latest_activity), yesterday)) {
                return `Igår ${this.format_time(latest_activity)}`
            } else {
                const difference = differenceInDays(today, new Date(latest_activity))
                return `${this.format_date(latest_activity)} (${difference} dagar sedan)`
            }
        },
        async get_statistics() {
            const result = await this.api_get({
                url: 'x-u2work--statistics-on-citizens',
                params: {
                    where: {
                        citizen: this.user['user'],
                        $and: [
                            {
                                week: {
                                    $in: Array.from(new Set(this.weeks.map((v) => v.week))),
                                },
                            },
                            {
                                year: {
                                    $in: Array.from(new Set(this.weeks.map((v) => v.year))),
                                },
                            },
                        ],
                    },
                },
            })
            this.statistics.push(...result.data['_items'])
            this.aggregate_statistics()
        },
        aggregate_statistics() {
            for (const v of this.weeks) {
                const statistic = this.statistics.find((x) => x.week === v.week && x.year === v.year)
                if (!statistic) {
                    this.minutes_spent_in_application.push(0)
                    this.clicks.push(0)
                    this.logged_on.push(0)
                } else {
                    this.minutes_spent_in_application.push(statistic['activity']['minutes_spent_in_application'])
                    this.clicks.push(statistic['activity']['clicks'])
                    this.logged_on.push(statistic['activity']['logged_on'])
                }
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.weeks = this.calculate_past_weeks(6)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
::v-deep .v-slide-group__wrapper
    border-right: 1px solid rgba(0, 0, 0, 0.12)
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
</style>
