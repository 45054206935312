<template>
    <v-row
        no-gutters
        :class="{ 'mx-4': $vuetify.breakpoint.smAndDown }"
    >
        <v-col
            v-for="(statistic, index) in statistics"
            :key="index"
            :md="4"
            cols="12"
            class="mb-4"
        >
            <v-card
                :class="{ 'mx-4': index !== 1 && !$vuetify.breakpoint.smAndDown }"
                outlined
            >
                <v-card-subtitle>
                    <v-icon class="mr-2">{{ statistic.icon }}</v-icon>
                    {{ statistic.title }}
                </v-card-subtitle>
                <v-card
                    height="150"
                    color="transparent"
                    class="pb-4 px-4"
                    outlined
                >
                    <LineChart
                        :labels="labels"
                        :datasets="generate_datasets(statistic)"
                    />
                </v-card>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import LineChart from '@/components/statistics/LineChart.vue'

export default {
    name: 'Statistics',
    props: {
        labels: {
            type: Array,
        },
        minutes_spent_in_application: {
            type: Array,
        },
        clicks: {
            type: Array,
        },
        logged_on: {
            type: Array,
        },
    },
    mixins: [],
    components: { LineChart },
    data() {
        return {
            statistics: [
                {
                    prop: this.minutes_spent_in_application,
                    title: 'Tid',
                    icon: 'mdi-timer-outline',
                },
                {
                    prop: this.clicks,
                    title: 'Aktivitet',
                    icon: 'mdi-run',
                },
                {
                    prop: this.logged_on,
                    title: 'Inloggningar',
                    icon: 'mdi-login-variant',
                },
            ],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        generate_datasets(statistic) {
            return [
                {
                    label: statistic.title,
                    backgroundColor: this.$vuetify.theme.currentTheme.primary,
                    borderColor: this.$vuetify.theme.currentTheme.primary,
                    data: statistic.prop,
                },
            ]
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
