import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import FlagIcon from 'vue-flag-icon'
import '@fortawesome/fontawesome-free/css/all.css'

import './styles/custom/index.sass'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(FlagIcon)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
