<template>
    <v-app
        v-scroll="on_scroll"
        :style="{ background }"
    >
        <TheSnackbars />
        <TheNavigationDrawer v-model="navigation_drawer" />
        <TheHeader
            ref="the_header"
            v-if="$route.name !== 'Login'"
            @navigation_drawer="navigation_drawer = !navigation_drawer"
        />
        <v-main>
            <v-container fluid>
                <router-view v-if="user_authenticated || $route.name === 'Login'" />
            </v-container>
        </v-main>
        <TheFooter v-if="$route.name !== 'Login'" />
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { scroll } from 'vuetify/lib/directives/scroll'
import { api_mixin } from '@/mixins/services/api_mixin'
import { utils_mixin } from '@/mixins/services/utils_mixin'
import TheHeader from '@/components/the_header'
import TheFooter from '@/components/the_footer'
import TheNavigationDrawer from '@/components/the_navigation_drawer'
import TheSnackbars from '@/components/the_snackbars'
import VueJwtDecode from 'vue-jwt-decode'

export default {
    name: 'App',
    directives: {
        scroll,
    },
    mixins: [api_mixin, utils_mixin],
    props: [],
    components: { TheSnackbars, TheNavigationDrawer, TheFooter, TheHeader },
    data() {
        return {
            navigation_drawer: false,
        }
    },
    computed: {
        user_authenticated() {
            return this.token
        },
        ...mapGetters({
            decoded_token: 'decoded_token',
        }),
        ...mapState(['token', 'token_has_expired', 'user_extended_data']),
        background() {
            if (this.$route.name === 'Login') {
                return this.$vuetify.theme.currentTheme.primary
            }

            return this.$vuetify.theme.currentTheme['background']
        },
    },
    watch: {
        token: {
            handler(val, old_val) {
                if (!val && old_val) {
                    this.$router.push({
                        name: 'Login',
                    })
                }
            },
            immediate: true,
        },
        token_has_expired: {
            handler(val) {
                if (val === true) {
                    this.refresh_token()
                }
            },
            immediate: true,
        },
    },
    methods: {
        on_scroll() {
            // Solves https://github.com/vuetifyjs/vuetify/issues/9993
            const { the_header } = this.$refs
            const { app_bar } = the_header.$refs

            if (app_bar.currentScroll < app_bar.currentThreshold) {
                app_bar.isActive = true
            }
        },
        process_url_query_parameters() {
            const config = {
                token: {
                    method: this.handle_token,
                    clear_path: true,
                },
                error: {
                    method: this.handle_token_error,
                    clear_path: true,
                },
            }

            let clear_path = false
            const query = this.$route.query
            Object.entries(config).forEach(([k, v]) => {
                if (query[k]) {
                    clear_path = clear_path || v.clear_path
                    v.method(query[k])
                }
            })

            if (clear_path) {
                this.$router.replace({ query: null })
            }
        },
        handle_token(token) {
            if (token) {
                this.set_state_property({
                    property: 'token',
                    data: token,
                })
            }
        },
        handle_token_error(token) {
            const data = VueJwtDecode.decode(token)
            this.push_state_property({
                property: 'messages',
                data: {
                    message: data['_locale_message'],
                    color: 'red',
                    timeout: 10 * 1000,
                },
            })
        },
        navigate_to_login_if_unauthorized() {
            if (!this.token && this.$route.name !== 'Login') {
                this.$router.push({
                    name: 'Login',
                })
            }
        },
        async refresh_token() {
            try {
                const result = await this.api_post({
                    url: 'authenticates/refresh-token',
                })
                if (result.status === 202) {
                    return // Token recently refreshed
                }

                this.set_state_property({
                    property: 'token',
                    data: result?.data?.token,
                })
            } catch (e) {
                this.set_state_property({
                    property: 'token',
                    data: null,
                })
                console.log('failed to refresh token')
            } finally {
                this.set_state_property({
                    property: 'token_has_expired',
                    data: false,
                })
            }
        },
        async get_user_extended_data() {
            if (!this.token) return
            const response = await this.api_get({
                url: 'user--extended-data-light',
            })
            if (response.status === 200) {
                this.set_state_property({ property: 'user_extended_data', data: response.data })
                this.set_state_property({ property: 'user_extended_data_loaded', data: true })
                if (response.data['user_details']['system_user']) {
                    this.set_state_property({ property: 'is_system_user', data: true })
                }
            }
        },
        async get_organization_features() {
            const response = await this.api_get({
                url: 'x-u2work--organization-features',
                params: {
                    where: {
                        organization: this.user_extended_data.organization,
                    },
                },
            })
            if (response.status === 200 && response.data['_items'].length) {
                const [item] = response.data['_items']
                for (const key in item.features) {
                    const data = item.features[key] || false
                    this.update_key_in_state_property({ property: 'features', data, key })
                }
            }
        },
        async get_access_group_templates() {
            if (!this.token) return
            const items = await this.api_get_all_items('access--group-templates', 'name')
            this.set_state_property({ property: 'access_group_templates', data: items })
        },
        async get_access_groups() {
            if (!this.token) return
            const items = await this.api_get_all_items(
                'access--groups',
                'name',
                {
                    access_group_template: {
                        $exists: true,
                    },
                    organization: this.user_extended_data.organization,
                },
                {
                    access_rights: 0,
                }
            )

            this.set_state_property({ property: 'access_groups', data: items })
        },
        async get_units() {
            if (!this.token) return
            const items = await this.api_get_all_items('organization--units', 'name', {
                organization: this.user_extended_data.organization,
            })
            this.set_state_property({ property: 'units', data: items })
        },
        ...mapActions(['set_state_property', 'push_state_property', 'update_key_in_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    async mounted() {
        this.process_url_query_parameters()
        this.navigate_to_login_if_unauthorized()
        await this.get_user_extended_data()
        await this.get_access_group_templates()
        if (this.deep_get(this.user_extended_data, 'user_details.external_id.system') !== "skills"){
            await this.get_access_groups()
        }
        await this.get_organization_features()
        await this.get_units()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style lang="sass"></style>
