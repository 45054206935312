import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2196f3',
                secondary: '#90cbf9',
                tertiary: '#f93943',
                quaternary: '#fcb0b3',
                accent: '#009688',
                error: '#ff5722',
                info: '#607d8b',
                success: '#4caf50',
                warning: '#ffc107',
                background: '#F1F4F8',
                black: '#6A6A6A',

                bronze: '#CD7F32',
                silver: '#C0C0C0',
                gold: '#ffc50e',
            },
        },
    },
})
