<template>
    <v-dialog
        width="500"
        v-model="internal_value"
        scrollable
    >
        <v-card>
            <div class="d-flex justify-space-between align-center">
                <v-card-title>Användare som aldrig loggat in</v-card-title>
                <v-btn
                    @click="internal_value = false"
                    class="mr-4"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider />
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                absolute
                bottom
            />
            <v-card
                height="70vh"
                class="overflow-y-auto"
                color="transparent"
                outlined
            >
                <v-list
                    ref="list"
                    v-if="items.length"
                >
                    <v-list-item
                        three-line
                        v-for="item in items"
                        :key="item['_id']"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ created(item) }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ email(item) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-card-text v-else> {{ loading ? 'Laddar' : 'Hittade inga användare' }}</v-card-text>
            </v-card>
            <v-toolbar>
                <div class="caption">
                    Sökfilter: {{ search_text || '-' }} <br />
                    Enhet: {{ unit_name(selected_unit) }}
                </div>
                <v-spacer />
                <v-btn
                    small
                    color="primary"
                    :disabled="loading || page === pages"
                    @click="get_more_users"
                >
                    Visa fler
                </v-btn>
            </v-toolbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { utils_mixin } from '@/mixins/services/utils_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'

export default {
    name: 'InactiveUsersDialog',
    props: {
        value: {
            type: Boolean,
        },
        all_user_ids: {
            type: Array,
        },
        search_text: {
            type: String,
        },
        selected_unit: {
            type: String,
        },
    },
    mixins: [api_mixin, utils_mixin, date_mixin],
    components: {},
    data() {
        return {
            loading: false,
            items: [],
            page: 1,
            pages: 1,
            max_results: 5,
            fetched_users: 0,
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'units']),
    },
    watch: {
        internal_value(value) {
            if (value) {
                this.get_users()
            } else {
                this.page = 1
                this.items = []
            }
        },
    },
    methods: {
        async get_users() {
            this.loading = true
            const { organization } = this.user_extended_data
            const where = {
                organization: organization,
            }
            if (this.search_text && this.search_text.trim().length > 1) {
                where['name'] = {
                    $regex: this.search_text.trim(),
                    $options: 'i',
                }
            }
            if (this.selected_unit) {
                where['$or'] = [
                    {
                        user_organization_unit: this.selected_unit,
                    },
                    {
                        'organization_units.unit': this.selected_unit,
                    },
                ]
            }
            const response = await this.api_get({
                url: 'users',
                params: {
                    where: where,
                    sort: 'name',
                    page: this.page,
                    max_results: this.max_results,
                    projection: {
                        name: 1,
                        email: 1,
                        email_oauth: 1,
                    },
                },
            })
            const total = response['data']['_meta']['total']
            const items = response['data']['_items']
            for (const item of items) {
                if (this.all_user_ids.includes(item['_id'])) continue
                this.items.push(item)
                this.fetched_users++
            }
            this.pages = Math.ceil(total / this.max_results)
            if (this.fetched_users < 15 && this.pages > this.page) {
                this.page++
                await this.get_users()
            } else {
                this.fetched_users = 0
                this.loading = false
                this.scroll()
            }
        },
        get_more_users() {
            this.page++
            this.get_users()
        },
        scroll() {
            if (!this.items.length) return
            this.$nextTick(() => {
                this.$refs.list.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
            })
        },
        email(item) {
            return item.email || item.email_oauth || 'E-post saknas'
        },
        created(item) {
            return 'Skapad: ' + this.format_date_time(item['_created'])
        },
        unit_name(unit_id) {
            const unit = this.units.find((v) => v['_id'] === unit_id)
            if (unit) {
                return unit['name']
            } else {
                return '-'
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
