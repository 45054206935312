<template>
    <v-tooltip
        :disabled="!level"
        bottom
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                :large="$vuetify.breakpoint.mdAndUp"
                :color="$vuetify.theme.currentTheme[level]"
                :style="css"
                v-on="on"
                v-bind="attrs"
            >
                mdi-trophy
            </v-icon>
        </template>
        <span>{{ translate(level) }}</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'Trophy',
    props: {
        level: {
            type: String,
        },
        custom_css: {
            type: Object,
            default: () => {},
        },
    },
    mixins: [locales_mixin],
    components: {},
    data() {
        return {}
    },
    computed: {
        css() {
            return {
                ...(!this.level ? { opacity: 0 } : {}),
                ...(this.level === 'none' ? { opacity: 0.1 } : {}),
                ...this.custom_css,
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
.none
  border: 1px solid rgba(0, 0, 0, 0.5)
</style>
