<template>
    <v-card
        class="pa-4"
        color="transparent"
        outlined
    >
        <Table
            @edit_row="edit_access_group"
            resource="access--groups"
            :where="where"
            :headers="headers"
            :key="table_key"
        >
            <v-btn
                @click="create_access_group"
                class="ml-2"
            >
                <v-icon left>mdi-access-point-plus</v-icon>
                Ny supportenhet
            </v-btn>
        </Table>
        <AccessGroupDialog
            v-model="show_dialog"
            v-if="show_dialog"
            @trigger_reload="trigger_reload"
            :selected_access_group="selected_access_group"
        />
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Table from '@/components/admin/Table.vue'
import AccessGroupDialog from '@/components/admin/AccessGroupDialog.vue'

export default {
    name: 'AccessGroups',
    props: {},
    mixins: [],
    components: { AccessGroupDialog, Table },
    data() {
        return {
            table_key: 0,
            headers: [
                {
                    text: 'Namn',
                    value: 'name',
                },
                {
                    text: 'Beskrivning',
                    value: 'description',
                },
            ],
            show_dialog: false,
            selected_access_group: {},
        }
    },
    computed: {
        where() {
            return {
                access_group_template: this.access_group_templates.find((v) => v.name === 'support_unit_access')['_id'],
            }
        },
        ...mapGetters([]),
        ...mapState(['access_group_templates']),
    },
    watch: {},
    methods: {
        create_access_group() {
            this.selected_access_group = {}
            this.show_dialog = true
        },
        edit_access_group(access_group) {
            this.selected_access_group = access_group
            this.show_dialog = true
        },
        trigger_reload(return_object) {
            this.table_key++
            if (Object.keys(return_object).length) {
                this.edit_access_group(return_object)
            }
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
