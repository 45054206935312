<template>
    <v-dialog
        v-model="internal_value"
        width="800"
        persistent
        scrollable
    >
        <v-card>
            <v-card-title>
                {{ title }}
                <v-spacer />
                <v-btn
                    v-if="editing"
                    @click="show_confirm_dialog = true"
                    :disabled="editing_yourself || saving"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-card
                    class="mt-4 mx-4"
                    v-if="!editing"
                    color="warning"
                    outlined
                >
                    <v-alert
                        border="left"
                        type="warning"
                        class="ma-0"
                        icon="mdi-alert-circle"
                        colored-border
                    >
                        Observera att endast anställda medarbetare / interna resurser ska ges behörighet genom nedan
                        valmöjligheter. Användare (t.ex. invånare) ska INTE ges behörighet via valmöjligheterna nedan.
                    </v-alert>
                </v-card>
                <v-card
                    class="mt-4 mx-4"
                    v-if="editing_yourself"
                    color="warning"
                    outlined
                >
                    <v-alert
                        border="left"
                        type="warning"
                        class="ma-0"
                        icon="mdi-alert-circle"
                        colored-border
                    >
                        Det går inte att redigera eller ta bort sin egna användare.
                    </v-alert>
                </v-card>
                <v-stepper
                    v-model="current_step"
                    vertical
                    flat
                >
                    <v-stepper-step
                        @click="current_step = 1"
                        style="cursor: pointer"
                        :rules="[() => !(name_error || email_error)]"
                        error-icon="mdi-alert-circle"
                        step="1"
                    >
                        Namn & e-post
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <v-card
                            :disabled="editing_yourself || saving"
                            outlined
                        >
                            <v-card-text>
                                Fyll i namn och e-post samt autentiseringsmetod
                                <v-text-field
                                    v-model="name"
                                    @input="name_error = ''"
                                    :error-messages="name_error"
                                    class="mt-4"
                                    label="Namn"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    v-model="email"
                                    @input="email_error = ''"
                                    :error-messages="email_error"
                                    label="E-post"
                                    outlined
                                    dense
                                />

                                Autentisering via
                                <v-radio-group v-model="authentication">
                                    <v-radio
                                        value="email"
                                        label="E-post"
                                    />
                                    <v-radio
                                        value="google"
                                        label="Google"
                                    />
                                    <v-radio
                                        value="microsoft"
                                        label="Microsoft"
                                    />
                                </v-radio-group>

                                Välj behörigheter (det går endast att administrera de behörigheter man själv är
                                tilldelad)
                                <v-expand-transition>
                                    <div
                                        v-show="locked_step"
                                        class="mt-2"
                                    >
                                        Enhetsadministratör tilldelas speciella accessrättigheter, vilket innebär att
                                        valen av enheter & supportenheter försvinner.
                                    </div>
                                </v-expand-transition>
                                <div
                                    class="d-flex"
                                    style="gap: 2rem"
                                >
                                    <v-checkbox
                                        v-model="x_u2work.is_coach"
                                        :disabled="user_access_level('is_coach')"
                                        label="Coach"
                                        hide-details
                                    />
                                    <v-checkbox
                                        v-model="x_u2work.is_coach_administrator"
                                        :disabled="user_access_level('is_coach_administrator')"
                                        label="Coachadministratör"
                                        hide-details
                                    />
                                    <v-checkbox
                                        v-model="x_u2work.is_unit_administrator"
                                        :disabled="user_access_level('is_unit_administrator')"
                                        label="Enhetsadministratör"
                                        hide-details
                                    />
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-step
                        @click="!locked_step ? (current_step = 2) : (current_step = 1)"
                        :disabled="locked_step"
                        :class="{ pointer: !locked_step, lock: locked_step }"
                        :rules="[() => !locked_step]"
                        error-icon="mdi-lock"
                        id="lock"
                        step="2"
                    >
                        Enheter & supportenheter
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-card
                            :disabled="editing_yourself || saving"
                            outlined
                        >
                            <v-tabs
                                v-model="current_tab"
                                fixed-tabs
                            >
                                <v-tab>Enheter</v-tab>
                                <v-tab>Supportenheter</v-tab>
                                <v-tab-item
                                    v-for="(group, i) in [selected_units, selected_support_units]"
                                    :key="i"
                                >
                                    <v-divider />
                                    <v-card
                                        outlined
                                        class="ma-4"
                                    >
                                        <v-list
                                            v-if="group.length"
                                            dense
                                        >
                                            <v-list-item
                                                v-for="(x, index) in group"
                                                :key="index"
                                            >
                                                <v-list-item-content>
                                                    {{ x.access_group_name }}
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn
                                                        @click="group.splice(index, 1)"
                                                        icon
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                        <v-card-text v-else>Tom</v-card-text>
                                    </v-card>
                                    <v-row
                                        no-gutters
                                        class="mx-4"
                                    >
                                        <v-autocomplete
                                            v-model="selected_data_access"
                                            :items="items(i)"
                                            item-text="name"
                                            :label="data_access_label(i)"
                                            :no-data-text="data_access_text(i)"
                                            dense
                                            outlined
                                            return-object
                                        />
                                        <v-btn
                                            @click="add_to_group(group)"
                                            :disabled="!Object.keys(selected_data_access).length"
                                            color="primary"
                                            class="ml-4"
                                        >
                                            Lägg till
                                        </v-btn>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                        <v-expand-transition>
                            <v-card
                                v-show="data_access_error"
                                class="mt-4"
                                color="error"
                                outlined
                            >
                                <v-alert
                                    border="left"
                                    type="error"
                                    class="ma-0"
                                    icon="mdi-alert-circle"
                                    colored-border
                                >
                                    {{ data_access_error }}
                                </v-alert>
                            </v-card>
                        </v-expand-transition>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
            <v-toolbar dense>
                <v-spacer />
                <v-btn
                    @click="internal_value = false"
                    :disabled="saving && !show_confirm_dialog"
                >
                    Avbryt
                </v-btn>
                <v-btn
                    @click="handle_next_or_save"
                    :disabled="editing_yourself"
                    :loading="saving && !show_confirm_dialog"
                    color="primary"
                    class="ml-2"
                >
                    {{ button }}
                </v-btn>
            </v-toolbar>
        </v-card>
        <v-dialog
            v-model="show_unit_administrator_dialog"
            max-width="300"
            persistent
        >
            <v-card>
                <v-card-title>Enhetsadministratör</v-card-title>
                <v-card-text>
                    Denna behörighet tilldelas speciella accessrättigheter, vilket innebär att valen av enheter &
                    supportenheter försvinner. Där finns redan valda enheter/supportenheter sedan tidigare som kommer
                    försvinna. Är du säker på att du vill fortsätta?
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancel_unit_administrator">Avbryt</v-btn>
                    <v-btn
                        @click="handle_unit_administrator"
                        color="primary"
                    >
                        Fortsätt
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmDialog
            v-model="show_confirm_dialog"
            :saving="saving"
            :disabled="coach_has_citizens"
            :title="confirm_title"
            :text="confirm_text"
            @confirm="delete_user"
        />
        <SelectedValueDialog
            v-model="show_selected_value_dialog"
            @skip="skip_and_save"
            @add="add_and_save"
            :name="selected_data_access['name']"
            admin_type="medarbetaren"
        />
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'
import { add } from 'date-fns'
import { access_mixin } from '@/mixins/services/access_mixin'
import ConfirmDialog from '@/components/admin/ConfirmDialog.vue'
import SelectedValueDialog from '@/components/admin/SelectedValueDialog.vue'
import { utils_mixin } from '@/mixins/services/utils_mixin'

export default {
    name: 'CoachDialog',
    props: {
        value: {
            type: Boolean,
        },
        selected_user: {
            type: Object,
        },
        applications: {
            type: Array,
        },
    },
    mixins: [api_mixin, date_mixin, access_mixin, utils_mixin],
    components: { SelectedValueDialog, ConfirmDialog },
    data() {
        return {
            current_step: 1,
            name: '',
            name_error: '',
            email: '',
            email_error: '',
            authentication: 'email',

            data_access_error: '',

            selected_data_access: {},
            selected_units: [],
            selected_support_units: [],

            saving: false,
            current_tab: 0,

            x_u2work: {
                is_coach: false,
                is_coach_administrator: false,
                is_unit_administrator: false,
            },

            show_unit_administrator_dialog: false,
            show_confirm_dialog: false,
            show_selected_value_dialog: false,
            coach_has_citizens: false,
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        editing() {
            return !!Object.keys(this.selected_user).length
        },
        title() {
            if (this.editing) {
                return 'Redigera medarbetare'
            } else {
                return 'Skapa ny medarbetare'
            }
        },
        button() {
            if (this.current_step === 2 || this.locked_step) {
                return this.editing ? 'Spara' : 'Skapa'
            } else {
                return 'Nästa'
            }
        },
        locked_step() {
            return this.x_u2work.is_unit_administrator
        },
        editing_yourself() {
            return this.selected_user['_id'] === this.user_extended_data['user']
        },
        confirm_text() {
            if (this.coach_has_citizens) {
                return 'Det går inte att radera coach som har invånare knutna till sig. Flytta först alla invånare till ny coach. Försök sen igen.'
            }
            return 'Är du helt säker på att du vill radera medarbetaren? Åtgärden kan inte ångras.'
        },
        confirm_title() {
            if (this.coach_has_citizens) {
                return 'Inte tillgänglig'
            }
            return 'VARNING!'
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'access_groups', 'access_group_templates']),
    },
    watch: {
        email(value) {
            if (value.includes('@gmail.')) this.authentication = 'google'
            if (value.includes('@live.')) this.authentication = 'microsoft'
            if (value.includes('@outlook.')) this.authentication = 'microsoft'
            if (value.includes('@hotmail.')) this.authentication = 'microsoft'
        },
        current_tab() {
            this.selected_data_access = {}
        },
        x_u2work: {
            handler(x) {
                this.data_access_error = ''
                if (x['is_unit_administrator']) {
                    if (this.selected_units.length || this.selected_support_units.length) {
                        this.show_unit_administrator_dialog = true
                    } else {
                        this.handle_unit_administrator()
                    }
                    this.x_u2work.is_coach_administrator = true
                    this.x_u2work.is_coach = true
                } else if (x['is_coach_administrator']) {
                    this.x_u2work.is_coach = true
                }
            },
            deep: true,
        },
        selected_units(val) {
            if (val.length) {
                this.data_access_error = ''
            }
        },
        selected_support_units(val) {
            if (val.length && !this.x_u2work.is_coach_administrator) {
                this.data_access_error = ''
            }
        },
    },
    methods: {
        cancel_unit_administrator() {
            this.x_u2work.is_unit_administrator = false
            this.show_unit_administrator_dialog = false
        },
        handle_unit_administrator() {
            this.show_unit_administrator_dialog = false
        },
        data_access_label(group_index) {
            if (group_index === 0) {
                return 'Enheter'
            } else {
                return 'Supportenheter'
            }
        },
        data_access_text(group_index) {
            if (group_index === 0) {
                return 'Inga enheter hittades'
            } else {
                return 'Inga supportenheter hittades'
            }
        },
        items(group_index) {
            if (group_index === 0) {
                const id = this.access_group_templates.find((v) => v.name === 'unit_access')['_id']
                const access_groups = this.access_groups.filter((v) => v.access_group_template === id)
                return access_groups.filter((v) => !this.selected_units.find((x) => x.access_group === v['_id']))
            } else {
                const id = this.access_group_templates.find((v) => v.name === 'support_unit_access')['_id']
                const access_groups = this.access_groups.filter((v) => v.access_group_template === id)
                return access_groups.filter(
                    (v) => !this.selected_support_units.find((x) => x.access_group === v['_id'])
                )
            }
        },
        add_to_group(group) {
            group.push({
                access_group: this.selected_data_access['_id'],
                access_group_name: this.selected_data_access['name'],
                from: new Date().toUTCString(),
                until: add(new Date(), { years: 30 }).toUTCString(),
            })
            this.selected_data_access = {}
        },
        handle_next_or_save() {
            this.name_error = ''
            this.email_error = ''

            if (!this.name) {
                this.name_error = 'Namn måste fyllas i'
            }

            const email_regex = new RegExp(
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
            )
            if (!email_regex.test(this.email)) {
                this.email_error = 'Ogiltig e-post'
            }

            if (this.name_error || this.email_error) return

            if (this.current_step === 2 || this.locked_step) {
                this.save()
            } else {
                this.current_step += 1
            }
        },
        user_access_level(key) {
            const map = {
                is_unit_administrator: 3,
                is_coach_administrator: 2,
                is_coach: 1,
            }
            let selected_level = 0
            for (const level in map) {
                if (this.x_u2work[level]) {
                    selected_level = map[level]
                    break
                }
            }
            if (selected_level > map[key]) return true
            const { user_details } = this.user_extended_data
            return !(user_details['system_user'] || user_details.x_u2work?.[key])
        },
        async save() {
            if (Object.keys(this.selected_data_access).length) {
                this.show_selected_value_dialog = true
                return
            }

            this.data_access_error = ''
            if (!this.x_u2work.is_unit_administrator) {
                if (this.x_u2work.is_coach_administrator) {
                    if (!this.selected_units.length) {
                        this.data_access_error = 'Minst en enhet måste väljas för coachadministratörer'
                        return
                    }
                } else if (this.x_u2work.is_coach) {
                    if (!this.selected_units.length && !this.selected_support_units.length) {
                        this.data_access_error = 'Minst en enhet eller supportenhet måste väljas för coacher'
                        return
                    }
                }
            }

            this.saving = true
            const data = {}

            data['name'] = this.name
            data['email'] = this.email
            data['organization'] = this.user_extended_data.organization
            data['organization_unit'] = this.coach_unit()

            if (this.authentication !== 'email') {
                data['email_oauth'] = this.email
                data['oauth_provider'] = this.authentication
            }

            data['application_access'] = [
                {
                    application: this.applications.find((x) => x['name'] === 'u2work')['_id'],
                },
                {
                    application: this.applications.find((x) => x['name'] === 'u2work_coach')['_id'],
                },
            ]
            data['data_access'] = [...this.selected_units, ...this.selected_support_units].map((x) => {
                const copy = { ...x }
                delete copy.access_group_name
                return copy
            })

            data['x_u2work.is_coach'] = this.x_u2work.is_coach
            data['x_u2work.is_coach_administrator'] = this.x_u2work.is_coach_administrator
            data['x_u2work.is_unit_administrator'] = this.x_u2work.is_unit_administrator

            if (this.x_u2work.is_unit_administrator) {
                const { _id } = this.access_groups.find((v) => v['access_group_template_name'] === 'unit_administrator')
                data['data_access'] = [
                    {
                        access_group: _id,
                        from: new Date().toUTCString(),
                        until: add(new Date(), { years: 30 }).toUTCString(),
                    },
                ]
            }

            const snackbar = {}
            if (this.editing) {
                try {
                    await this.api_patch({
                        url: `users/${this.selected_user['_id']}`,
                        if_match: this.selected_user['_etag'],
                        data: data,
                    })
                    snackbar['message'] = 'Sparat'
                    snackbar['color'] = 'success'
                } catch (e) {
                    snackbar['message'] = 'Något gick fel, försök igen senare'
                    snackbar['color'] = 'error'
                }
            } else {
                try {
                    await this.api_post({
                        url: 'users',
                        data: data,
                    })
                    snackbar['message'] = 'Skapad'
                    snackbar['color'] = 'success'
                } catch (e) {
                    snackbar['message'] = 'Något gick fel, försök igen senare'
                    snackbar['color'] = 'error'
                }
            }
            await this.push_state_property({
                property: 'messages',
                data: snackbar,
            })

            this.saving = false
            this.internal_value = false
            this.$emit('trigger_reload')
        },
        async delete_user() {
            this.saving = true
            const response = await this.api_delete({
                url: `users/${this.selected_user['_id']}`,
                if_match: this.selected_user['_etag'],
            })
            if (response.status === 204) {
                const snackbar = {
                    message: 'Raderad',
                    color: 'success',
                }
                await this.push_state_property({
                    property: 'messages',
                    data: snackbar,
                })
            }
            this.internal_value = false
            this.$emit('trigger_reload')
        },
        skip_and_save() {
            this.show_selected_value_dialog = false
            this.selected_data_access = {}
            this.save()
        },
        add_and_save() {
            this.show_selected_value_dialog = false
            if (this.current_tab === 0) {
                this.add_to_group(this.selected_units)
            } else {
                this.add_to_group(this.selected_support_units)
            }
            this.save()
        },
        async check_for_citizens() {
            const response = await this.api_get({
                url: 'users',
                params: {
                    where: {
                        manager: this.selected_user['_id'],
                    },
                    projection: {
                        _id: 1,
                    },
                    max_results: 1,
                },
            })
            this.coach_has_citizens = response['data']['_meta']['total'] !== 0
        },
        ...mapActions(['push_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        if (this.editing) {
            this.check_for_citizens()
            this.name = this.selected_user['name']
            this.email = this.selected_user['email_oauth'] || this.selected_user['email']
            this.authentication = this.selected_user['oauth_provider'] || 'email'

            const unit_access_id = this.access_group_templates.find((v) => v.name === 'unit_access')['_id']
            const support_unit_access_id = this.access_group_templates.find((v) => v.name === 'support_unit_access')[
                '_id'
            ]
            for (const data_access of this.selected_user['data_access'] || []) {
                const access_group = this.access_groups.find((v) => v['_id'] === data_access['access_group'])
                if (!access_group) continue
                if (access_group.access_group_template === unit_access_id) {
                    this.selected_units.push({
                        access_group: data_access['access_group'],
                        access_group_name: data_access['access_group_name'],
                        from: data_access['from'],
                        until: data_access['until'],
                    })
                } else if (access_group.access_group_template === support_unit_access_id) {
                    this.selected_support_units.push({
                        access_group: data_access['access_group'],
                        access_group_name: data_access['access_group_name'],
                        from: data_access['from'],
                        until: data_access['until'],
                    })
                }
            }
            const { x_u2work } = this.selected_user

            if (x_u2work?.is_coach) this.x_u2work.is_coach = true
            if (x_u2work?.is_coach_administrator) this.x_u2work.is_coach_administrator = true
            if (x_u2work?.is_unit_administrator) this.x_u2work.is_unit_administrator = true
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass">
.pointer
    cursor: pointer

::v-deep .lock .v-stepper__label,
::v-deep .lock span
    color: rgba(0, 0, 0, 0.38) !important
</style>
