<template>
    <v-dialog
        v-model="internal_value"
        max-width="700"
        scrollable
    >
        <v-card>
            <v-card-title>Frågor & svar</v-card-title>
            <v-divider />
            <v-card-text
                class="pb-0"
                style="height: 400px; background-color: #eee"
            >
                <v-timeline dense>
                    <v-timeline-item
                        v-for="(item, index) in play_sequence"
                        :key="index"
                        :color="item['feedback_color']"
                        :icon="`fa-${item['feedback_icon']}`"
                        icon-color="#333"
                        fill-dot
                        large
                    >
                        <v-card>
                            <v-card-subtitle class="font-weight-bold">
                                {{ index + 1 }}. {{ item['question'] }}
                            </v-card-subtitle>
                            <v-card-text>
                                {{ item['answer'] }}
                            </v-card-text>
                            <v-card-text class="subtitle-2"> {{ item['score'] }} poäng</v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    @click="internal_value = false"
                    color="primary"
                >
                    Stäng
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'InterviewPlaySequence',
    props: {
        value: {
            type: Boolean,
        },
        play_sequence: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
