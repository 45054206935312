export const access_mixin = {
    methods: {
        is_coach(user) {
            const { x_u2work } = user
            return x_u2work?.is_coach || false
        },
        is_coach_administrator(user) {
            const { x_u2work } = user
            return x_u2work?.is_coach_administrator || false
        },
        is_unit_administrator(user) {
            const { x_u2work } = user
            return x_u2work?.is_unit_administrator || false
        },
    },
}
