<template>
    <v-menu
        transition="scale-transition"
        origin="top right"
        nudge-right="36"
        offset-y
        offset-x
        left
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon color="black">mdi-dots-horizontal</v-icon>
            </v-btn>
        </template>
        <v-list
            class="pa-0"
            dense
        >
            <v-list-item
                @click="item.action()"
                v-for="(item, index) in actions"
                :disabled="!item.enabled"
                :key="index"
            >
                <v-list-item-subtitle class="d-flex align-center">
                    <v-icon class="mr-2">{{ item.icon }}</v-icon>
                    {{ item.name }}
                </v-list-item-subtitle>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'Actions',
    props: {
        item: {
            type: Object,
        },
    },
    mixins: [],
    components: {},
    data() {
        return {
            actions: [],
        }
    },
    computed: {
        email() {
            return this.item['user_email']
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        send_email() {
            window.open(`mailto:${this.email}?subject=U2Work`, '_blank')
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        const enabled = !!this.email
        this.actions.push({
            name: 'Skicka e-post',
            icon: 'mdi-email-fast-outline',
            enabled: enabled,
            action: () => this.send_email(),
        })
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
