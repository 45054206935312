<template>
    <v-navigation-drawer
        app
        v-model="internal_value"
    >
        <div style="height: calc(100% - 50px)">
            <v-toolbar flat>
                <v-toolbar-title>{{ user_extended_data['user_name'] }}</v-toolbar-title>
                <v-spacer />
                <v-btn
                    @click="internal_value = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider />
            <v-list class="pa-0">
                <v-list-item
                    v-for="(page, index) in pages"
                    :key="index"
                    :to="page.to"
                    color="primary"
                >
                    <v-list-item-icon>
                        <v-icon>{{ page.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ page.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </v-list>
        </div>
        <v-divider />
        <v-card
            color="transparent"
            height="48"
            class="d-flex justify-center align-center"
            outlined
        >
            <v-btn
                color="primary"
                @click="logout"
                text
                >Logga ut
            </v-btn>
        </v-card>
    </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'TheNavigationDrawer',
    props: {
        value: {
            type: Boolean,
        },
    },
    mixins: [],
    components: {},
    data() {
        return {
            pages: [
                {
                    to: 'users',
                    icon: 'mdi-account-group',
                    text: 'Invånare',
                },
                {
                    to: 'settings',
                    icon: 'mdi-cogs',
                    text: 'Inställningar',
                },
            ],
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'features', 'is_system_user', 'token']),
    },
    watch: {
        features: {
            handler(features) {
                if (features.coach_admin || this.is_system_user) {
                    this.pages.splice(1, 0, {
                        to: 'admin',
                        icon: 'mdi-security',
                        text: 'Administration',
                    })
                }
                if (
                    features.statistics_organization ||
                    features.statistics_units ||
                    features.statistics_coach ||
                    this.is_system_user
                ) {
                    this.pages.splice(2, 0, {
                        to: 'statistics',
                        icon: 'mdi-chart-bar',
                        text: 'Statistik',
                    })
                }
            },
            deep: true,
        },
        token(value) {
            if (!value) this.internal_value = false
        },
    },
    methods: {
        logout() {
            this.set_state_property({
                property: 'token',
                data: null,
            })
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
