export const locales_mixin = {
    methods: {
        translate_dict(locale_dict) {
            if (!locale_dict) {
                return `Locale dict not found`
            }

            const selected_locale = `locale_${this.$i18n.locale}`

            if (locale_dict[selected_locale]) {
                return locale_dict[selected_locale]
            }

            // const en_translation = locale_dict.locale_en
            // if() TODO: load from translations list

            return locale_dict.locale_fallback
        },
        translate(locale_key) {
            if (locale_key && this.$te(locale_key)) return this.$t(locale_key)
            return locale_key
        },
    },
}
