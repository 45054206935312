<template>
    <v-dialog
        v-model="internal_value"
        width="300"
        :persistent="saving"
    >
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>{{ text }}</v-card-text>

            <v-toolbar dense>
                <v-spacer />
                <v-btn
                    @click="internal_value = false"
                    :disabled="saving"
                >
                    Avbryt
                </v-btn>
                <v-btn
                    @click="$emit('confirm')"
                    :loading="saving"
                    :disabled="disabled"
                    color="error"
                    class="ml-2"
                >
                    Radera
                </v-btn>
            </v-toolbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'ConfirmDialog',
    props: {
        value: {
            type: Boolean,
        },
        title: {
            type: String,
        },
        text: {
            type: String,
        },
        saving: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
