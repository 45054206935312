<template>
    <v-dialog
        v-model="internal_value"
        width="800"
        persistent
        scrollable
    >
        <v-card>
            <v-card-title>
                {{ title }}
                <v-spacer />
                <v-btn
                    v-if="editing"
                    @click="show_confirm_dialog = true"
                    :disabled="editing_yourself || saving"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-card
                    class="mt-4 mx-4"
                    v-if="editing_yourself"
                    color="warning"
                    outlined
                >
                    <v-alert
                        border="left"
                        type="warning"
                        class="ma-0"
                        icon="mdi-alert-circle"
                        colored-border
                    >
                        Det går inte att redigera eller ta bort sin egna användare.
                    </v-alert>
                </v-card>
                <v-stepper
                    v-model="current_step"
                    vertical
                    flat
                >
                    <v-stepper-step
                        @click="current_step = 1"
                        style="cursor: pointer"
                        :rules="[() => !(this.name_error || this.email_error || this.manager_error)]"
                        error-icon="mdi-alert-circle"
                        step="1"
                    >
                        Namn, e-post & coach
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <v-card
                            :disabled="editing_yourself || saving"
                            outlined
                        >
                            <v-card-text>
                                Fyll i namn och e-post samt autentiseringsmetod
                                <v-text-field
                                    v-model="name"
                                    @input="name_error = ''"
                                    :error-messages="name_error"
                                    class="mt-4"
                                    label="Namn"
                                    outlined
                                    dense
                                />
                                <v-text-field
                                    v-model="email"
                                    @input="email_error = ''"
                                    :error-messages="email_error"
                                    label="E-post"
                                    outlined
                                    dense
                                />

                                Autentisering via
                                <v-radio-group
                                    v-model="authentication"
                                    :column="false"
                                    class="mt-2 mb-4"
                                >
                                    <v-radio
                                        value="email"
                                        label="E-post"
                                        class="mr-4"
                                    />
                                    <v-radio
                                        value="google"
                                        label="Google"
                                        class="mr-4"
                                    />
                                    <v-radio
                                        value="microsoft"
                                        label="Microsoft"
                                    />
                                </v-radio-group>

                                Fyll i enhet och sedan coach
                                <v-autocomplete
                                    v-model="unit"
                                    @input="unit_error = ''"
                                    :items="units"
                                    :error-messages="unit_error"
                                    item-text="name"
                                    item-value="_id"
                                    no-data-text="Inga tillgängliga"
                                    label="Enhet"
                                    class="mt-4"
                                    outlined
                                    dense
                                />
                                <v-autocomplete
                                    v-model="manager"
                                    :disabled="!unit"
                                    @input="manager_error = ''"
                                    :items="available_managers"
                                    :error-messages="manager_error"
                                    no-data-text="Inga tillgängliga"
                                    item-text="name"
                                    item-value="_id"
                                    label="Coach"
                                    outlined
                                    dense
                                />
                            </v-card-text>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-step
                        @click="current_step = 2"
                        style="cursor: pointer"
                        step="2"
                    >
                        Supportenheter
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-card
                            :disabled="editing_yourself || saving"
                            outlined
                        >
                            <v-card-text>Lägg till supportenheter i listan nedan</v-card-text>
                            <v-row no-gutters>
                                <v-autocomplete
                                    :items="unselected_access_groups"
                                    v-model="selected_access_group"
                                    item-text="name"
                                    label="Supportenhet"
                                    no-data-text="Inga tillgängliga"
                                    class="mx-4"
                                    return-object
                                    outlined
                                    dense
                                />
                                <v-btn
                                    @click="add_access_group"
                                    :disabled="!Object.keys(selected_access_group).length"
                                    class="mr-4"
                                    right
                                >
                                    <v-icon>mdi-plus</v-icon>
                                    Lägg till
                                </v-btn>
                            </v-row>
                            <v-divider />
                            <v-card-text>Valda supportenheter ({{ selected_access_groups.length }})</v-card-text>
                            <v-list
                                v-if="selected_access_groups.length"
                                dense
                            >
                                <v-list-item
                                    v-for="(x, index) in selected_access_groups"
                                    :key="index"
                                    two-line
                                >
                                    <v-list-item-content>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-list-item-title>
                                                    {{ x['name'] }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ x['organization_name'] }}
                                                </v-list-item-subtitle>
                                            </v-col>
                                            <v-col class="d-flex justify-end">
                                                <v-btn
                                                    @click="selected_access_groups.splice(index, 1)"
                                                    icon
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
            <v-toolbar dense>
                <v-spacer />
                <v-btn
                    @click="internal_value = false"
                    :disabled="saving && !show_confirm_dialog"
                >
                    Avbryt
                </v-btn>
                <v-btn
                    @click="handle_next_or_save"
                    :disabled="saving && !show_confirm_dialog"
                    color="primary"
                    class="ml-2"
                    :loading="saving"
                >
                    {{ button }}
                </v-btn>
            </v-toolbar>
        </v-card>
        <ConfirmDialog
            v-model="show_confirm_dialog"
            :saving="saving"
            title="VARNING!"
            text="Tänk på att användaren du raderar kan ha CV och Personligt Brev skrivna. Är du helt säker på att du vill radera användaren?"
            @confirm="delete_user"
        />
        <SelectedValueDialog
            v-model="show_selected_value_dialog"
            @skip="skip_and_save"
            @add="add_and_save"
            :name="selected_access_group['name']"
            admin_type="invånaren"
        />
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'
import { add } from 'date-fns'
import ConfirmDialog from '@/components/admin/ConfirmDialog.vue'
import SelectedValueDialog from '@/components/admin/SelectedValueDialog.vue'

export default {
    name: 'CitizenDialog',
    props: {
        value: {
            type: Boolean,
        },
        selected_user: {
            type: Object,
        },
        managers: {
            type: Array,
        },
        applications: {
            type: Array,
        },
    },
    mixins: [api_mixin, date_mixin],
    components: { SelectedValueDialog, ConfirmDialog },
    data() {
        return {
            current_step: 1,
            name: '',
            name_error: '',
            email: '',
            email_error: '',
            authentication: 'email',
            manager: '',
            manager_error: '',
            unit: '',
            unit_error: '',
            selected_access_groups: [],
            selected_access_groups_original: [],
            selected_access_group: {},
            saving: false,
            show_confirm_dialog: false,
            show_selected_value_dialog: false,
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        available_managers() {
            const access_group = this.access_groups.find((a) => {
                const units = a.organization_units || []
                return units.some((v) => v['unit'] === this.unit)
            })
            if (access_group) {
                return this.managers.filter((manager) => {
                    const access = (manager['data_access'] || []).map((v) => v['access_group'])
                    return access.some((v) => v === access_group['_id'])
                })
            }
            return []
        },
        editing() {
            return !!Object.keys(this.selected_user).length
        },
        title() {
            if (this.editing) {
                return 'Redigera invånare'
            } else {
                return 'Skapa ny invånare'
            }
        },
        button() {
            if (this.current_step === 2) {
                return this.editing ? 'Spara' : 'Skapa'
            } else {
                return 'Nästa'
            }
        },
        access_group_template() {
            return this.access_group_templates.find((v) => v.name === 'support_unit_access')['_id']
        },
        unselected_access_groups() {
            const access_group = this.access_groups.filter(
                (v) => v.access_group_template === this.access_group_template
            )
            return access_group.filter((x) => {
                return !this.selected_access_groups.find((y) => y['_id'] === x['_id'])
            })
        },
        editing_yourself() {
            return this.selected_user['_id'] === this.user_extended_data['user']
        },
        ...mapGetters([]),
        ...mapState(['access_groups', 'access_group_templates', 'units', 'user_extended_data']),
    },
    watch: {
        email(value) {
            if (value.includes('@gmail.')) this.authentication = 'google'
            if (value.includes('@live.')) this.authentication = 'microsoft'
            if (value.includes('@outlook.')) this.authentication = 'microsoft'
            if (value.includes('@hotmail.')) this.authentication = 'microsoft'
        },
    },
    methods: {
        handle_next_or_save() {
            this.name_error = ''
            this.email_error = ''
            this.manager_error = ''
            this.unit_error = ''

            if (!this.name) {
                this.name_error = 'Namn måste fyllas i'
            }

            const email_regex = new RegExp(
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
            )
            if (!email_regex.test(this.email)) {
                this.email_error = 'Ogiltig e-post'
            }

            if (!this.manager) {
                this.manager_error = 'Coach måste väljas'
            }

            if (!this.unit) {
                this.unit_error = 'Invånarenhet måste väljas'
            }

            if (this.name_error || this.email_error || this.manager_error || this.unit_error) return

            if (this.current_step === 2) {
                this.save()
            } else {
                this.current_step += 1
            }
        },
        add_access_group() {
            this.selected_access_groups.push(this.selected_access_group)
            this.selected_access_group = {}
        },
        async save() {
            if (Object.keys(this.selected_access_group).length) {
                this.show_selected_value_dialog = true
                return
            }

            this.saving = true
            const data = {}

            data['name'] = this.name
            data['email'] = this.email
            data['organization'] = this.user_extended_data.organization

            if (this.authentication !== 'email') {
                data['email_oauth'] = this.email
                data['oauth_provider'] = this.authentication
            }

            data['data_access'] = [
                {
                    access_group: this.get_u2work_access_group(),
                    from: new Date().toUTCString(),
                    until: add(new Date(), { years: 50 }).toUTCString(),
                },
            ]
            data['application_access'] = [
                {
                    application: this.applications.find((x) => x['name'] === 'u2work')['_id'],
                },
            ]
            data['manager'] = this.manager
            data['organization_unit'] = this.unit

            const snackbar = {}
            let close_dialog = true
            try {
                if (this.editing) {
                    await this.save_access_groups(this.selected_user['_id'])
                    await this.api_patch({
                        url: `users/${this.selected_user['_id']}`,
                        if_match: this.selected_user['_etag'],
                        data: data,
                    })
                    snackbar['message'] = 'Sparat'
                } else {
                    const response = await this.api_post({
                        url: 'users',
                        data: data,
                    })
                    await this.save_access_groups(response.data['_id'])
                    snackbar['message'] = 'Skapad'
                }
                snackbar['color'] = 'success'
            } catch ({ response }) {
                const issues = Object.values(response.data['_issues'])
                if (response.status === 422 && issues.some((v) => v.includes('unique'))) {
                    snackbar['message'] = 'E-postadressen är redan registrerad i systemet'
                    this.email_error = 'E-postadressen är redan registrerad i systemet'
                    close_dialog = false
                } else {
                    snackbar['message'] = 'Något gick fel, försök igen senare'
                }
                snackbar['color'] = 'error'
            }

            await this.push_state_property({
                property: 'messages',
                data: snackbar,
            })

            this.saving = false

            if (close_dialog) {
                this.internal_value = false
                this.$emit('trigger_reload')
            }
        },
        async save_access_groups(user_id) {
            const originals = this.selected_access_groups_original.map((x) => x['_id'])
            const current = this.selected_access_groups.map((x) => x['_id'])
            const new_groups = current.filter((x) => !originals.includes(x))
            const removed_groups = originals.filter((x) => !current.includes(x))

            for await (const new_group of new_groups) {
                const access_group = this.selected_access_groups.find((v) => v['_id'] === new_group)
                const users = access_group['users'] || []
                users.push({ user: user_id })
                await this.api_patch({
                    url: `access--groups/${access_group['_id']}`,
                    if_match: access_group['_etag'],
                    data: {
                        users: users.map((user) => {
                            const data = { ...user }
                            delete data['user_name']
                            return data
                        }),
                    },
                })
            }

            for await (const removed_group of removed_groups) {
                const access_group = this.selected_access_groups_original.find((v) => v['_id'] === removed_group)
                const users = access_group['users'].filter((v) => v['user'] !== this.selected_user['_id'])
                await this.api_patch({
                    url: `access--groups/${access_group['_id']}`,
                    if_match: access_group['_etag'],
                    data: {
                        users: users.map((user) => {
                            const data = { ...user }
                            delete data['user_name']
                            return data
                        }),
                    },
                })
            }

            await this.reload_updated_access_groups([...new_groups, ...removed_groups])
        },
        async reload_updated_access_groups(groups) {
            for (const id of groups) {
                const index = this.access_groups.findIndex((x) => x['_id'] === id)
                await this.splice_state_property({
                    property: 'access_groups',
                    index: index,
                })
                const response = await this.api_get({
                    url: `access--groups/${id}`,
                })
                await this.push_state_property({
                    property: 'access_groups',
                    data: response.data,
                })
            }
        },
        async delete_user() {
            this.saving = true
            const response = await this.api_delete({
                url: `users/${this.selected_user['_id']}`,
                if_match: this.selected_user['_etag'],
            })
            if (response.status === 204) {
                const snackbar = {
                    message: 'Raderad',
                    color: 'success',
                }
                await this.push_state_property({
                    property: 'messages',
                    data: snackbar,
                })
            }
            this.internal_value = false
            this.$emit('trigger_reload')
        },
        get_u2work_access_group() {
            const u2work_access_group = {
                test: '63637e711eb7c285a725ad7e',
                prod: '6448039fb0cb71797ac28255',
            }
            if (window.location.host.includes('localhost')) {
                return u2work_access_group['test']
            } else if (window.location.host.includes('test')) {
                return u2work_access_group['test']
            } else {
                return u2work_access_group['prod']
            }
        },
        skip_and_save() {
            this.show_selected_value_dialog = false
            this.selected_access_group = {}
            this.save()
        },
        add_and_save() {
            this.show_selected_value_dialog = false
            this.add_access_group()
            this.save()
        },
        ...mapActions(['push_state_property', 'splice_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        if (this.editing) {
            this.name = this.selected_user['name']
            this.email = this.selected_user['email_oauth'] || this.selected_user['email']
            this.authentication = this.selected_user['oauth_provider'] || 'email'
            if (this.selected_user['manager']) {
                this.manager = this.selected_user['manager']
            }
            if (this.selected_user['organization_unit']) {
                this.unit = this.selected_user['organization_unit']
            }

            this.selected_access_groups = this.access_groups.filter((x) => {
                return (x['users'] || []).find((y) => y.user === this.selected_user['_id'])
            })
            this.selected_access_groups_original = [...this.selected_access_groups]
        } else {
            if (this.managers.length === 1) {
                const [manager] = this.managers
                this.manager = manager['_id']
            }
        }
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
