<template>
    <v-dialog
        v-model="internal_value"
        width="800"
        persistent
        scrollable
    >
        <v-card>
            <v-card-title>
                {{ title }}
                <v-spacer />
                <v-btn
                    v-if="editing"
                    @click="show_confirm_dialog = true"
                    :disabled="saving"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-card
                    :disabled="saving"
                    class="mt-4"
                    outlined
                >
                    <v-card-text>{{ paragraph }}</v-card-text>
                    <v-text-field
                        label="Namn"
                        class="mx-4"
                        v-model="name"
                        :error-messages="name_error"
                        @input="name_error = ''"
                        outlined
                        dense
                    />
                </v-card>
                <v-card
                    :disabled="saving"
                    class="mt-4"
                    outlined
                >
                    <v-tabs
                        v-model="current_tab"
                        fixed-tabs
                    >
                        <v-tab :disabled="!editing">Invånare</v-tab>
                        <v-tab :disabled="!editing">Coacher</v-tab>
                        <v-tab-item
                            v-for="(group, i) in [members, administrators]"
                            :key="i"
                        >
                            <v-divider />
                            <v-card
                                outlined
                                class="ma-4"
                            >
                                <v-list
                                    v-if="group.length"
                                    dense
                                >
                                    <v-list-item
                                        v-for="(x, index) in group"
                                        :key="index"
                                        two-line
                                    >
                                        <v-list-item-content>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <v-list-item-title>
                                                        {{ x.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ x.email_oauth || x.email }}
                                                    </v-list-item-subtitle>
                                                </v-col>
                                                <v-col class="d-flex justify-end">
                                                    <v-btn
                                                        @click="remove_from_group(group, i, x, index)"
                                                        icon
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-card-text v-else>{{ group_text }}</v-card-text>
                            </v-card>
                            <v-row
                                no-gutters
                                class="mx-4"
                            >
                                <v-autocomplete
                                    v-model="selected_user"
                                    :items="users_not_in_group(group)"
                                    :disabled="!editing"
                                    item-text="name"
                                    :label="label"
                                    no-data-text="Inga användare hittades"
                                    dense
                                    outlined
                                    return-object
                                >
                                    <template #item="{ item }">
                                        {{ item.name }}
                                        <v-icon
                                            v-if="is_coach_administrator(item)"
                                            class="ml-2"
                                        >
                                            mdi-shield-crown
                                        </v-icon>
                                        <v-icon
                                            v-else-if="is_coach(item)"
                                            class="ml-2"
                                        >
                                            mdi-shield-crown-outline
                                        </v-icon>
                                    </template>
                                </v-autocomplete>
                                <v-btn
                                    @click="add_to_group(group, i)"
                                    :disabled="!Object.keys(selected_user).length"
                                    color="primary"
                                    class="ml-4"
                                >
                                    Lägg till
                                </v-btn>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-card-text>

            <v-toolbar dense>
                <v-spacer />
                <v-btn
                    :disabled="saving && !show_confirm_dialog"
                    @click="internal_value = false"
                >
                    Avbryt
                </v-btn>
                <v-btn
                    @click="save"
                    :loading="saving && !show_confirm_dialog"
                    color="primary"
                    class="ml-2"
                >
                    Spara
                </v-btn>
            </v-toolbar>
        </v-card>
        <ConfirmDialog
            v-model="show_confirm_dialog"
            :saving="saving"
            title="Är du säker?"
            text="Åtgärden kan inte ångras."
            @confirm="delete_access_group"
        />
        <SelectedValueDialog
            v-model="show_selected_value_dialog"
            @skip="skip_user_and_save"
            @add="add_user_and_save"
            :name="selected_user['name']"
            admin_type="supportenheten"
        />
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import ConfirmDialog from '@/components/admin/ConfirmDialog.vue'
import { add } from 'date-fns'
import { access_mixin } from '@/mixins/services/access_mixin'
import SelectedValueDialog from '@/components/admin/SelectedValueDialog.vue'

export default {
    name: 'AccessGroupDialog',
    props: {
        value: {
            type: Boolean,
        },
        selected_access_group: {
            type: Object,
        },
    },
    mixins: [access_mixin, api_mixin],
    components: { SelectedValueDialog, ConfirmDialog },
    data() {
        return {
            name: '',
            name_error: '',
            show_confirm_dialog: false,
            show_selected_value_dialog: false,
            saving: false,
            current_tab: 0,
            members: [],
            administrators: [],
            original_administrators: [],
            users: [],
            loading_groups: true,
            selected_user: {},
            return_object: {},
        }
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        label() {
            return this.current_tab === 0 ? 'Invånare' : 'Coacher'
        },
        group_text() {
            if (this.loading_groups && this.editing) return 'Laddar...'
            return 'Tom'
        },
        editing() {
            return !!Object.keys(this.selected_access_group).length
        },
        title() {
            let string = ''
            if (this.editing) {
                string += 'Redigera '
            } else {
                string += 'Skapa ny '
            }

            string += 'supportenhet'
            return string
        },
        paragraph() {
            if (this.editing) {
                return 'Ändra namn eller radera supportenhet'
            } else {
                return 'Fyll i namn och spara för att sedan kunna lägga till medlemmar och administratörer.'
            }
        },
        ...mapGetters([]),
        ...mapState(['access_groups', 'access_group_templates', 'user_extended_data']),
    },
    watch: {
        current_tab() {
            this.selected_user = {}
        },
        editing: {
            async handler(value) {
                if (value) {
                    this.name = this.selected_access_group['name']
                    await this.get_users()
                    await this.get_members()
                    await this.get_administrators()
                    this.loading_groups = false
                }
                this.loading = false
            },
            immediate: true,
        },
    },
    methods: {
        async save() {
            if (!this.name) {
                this.name_error = 'Namn måste fyllas i'
            } else {
                this.name_error = ''
            }
            if (this.name_error) return

            if (Object.keys(this.selected_user).length) {
                this.show_selected_value_dialog = true
                return
            }

            this.saving = true

            const data = {}

            data['name'] = this.name
            data['organization'] = this.user_extended_data.organization
            data['access_group_template'] = this.access_group_templates.find((v) => v.name === 'support_unit_access')[
                '_id'
            ]

            const users = []
            for (const user of this.members) {
                users.push({ user: user['_id'] })
            }
            data['users'] = users

            const snackbar = {}
            await this.update_users()
            if (this.editing) {
                try {
                    await this.api_patch({
                        url: `access--groups/${this.selected_access_group['_id']}`,
                        if_match: this.selected_access_group['_etag'],
                        data: data,
                    })
                    this.return_object = {}
                    snackbar['message'] = 'Sparat'
                    snackbar['color'] = 'success'
                } catch (e) {
                    snackbar['message'] = 'Något gick fel, försök igen senare'
                    snackbar['color'] = 'error'
                }
            } else {
                try {
                    const access_group = await this.api_post({
                        url: 'access--groups',
                        data: data,
                    })
                    await this.get_access_group(access_group.data['_id'])
                    snackbar['message'] = 'Skapad'
                    snackbar['color'] = 'success'
                } catch (e) {
                    snackbar['message'] = 'Något gick fel, försök igen senare'
                    snackbar['color'] = 'error'
                }
            }
            await this.push_state_property({
                property: 'messages',
                data: snackbar,
            })
            this.saving = false
            this.internal_value = false
            this.$emit('trigger_reload', this.return_object)
        },
        async get_access_group(access_group_id) {
            const response = await this.api_get({
                url: 'access--groups',
                params: {
                    where: {
                        _id: access_group_id,
                    },
                },
            })
            if (response.status === 200) {
                const [item] = response.data['_items']
                await this.push_state_property({
                    property: 'access_groups',
                    data: item,
                })
                this.return_object = item
            }
        },
        async delete_access_group() {
            this.saving = true
            const snackbar = {}
            try {
                const access_group_id = this.selected_access_group['_id']
                await this.api_delete({
                    url: `access--groups/${access_group_id}`,
                    if_match: this.selected_access_group['_etag'],
                })
                const index = this.access_groups.findIndex((v) => v['_id'] === access_group_id)
                await this.splice_state_property({
                    property: 'access_groups',
                    index: index,
                })
                snackbar['message'] = 'Raderad'
                snackbar['color'] = 'success'
            } catch (e) {
                snackbar['message'] = 'Något gick fel, försök igen senare'
                snackbar['color'] = 'error'
            }
            await this.push_state_property({
                property: 'messages',
                data: snackbar,
            })
            this.internal_value = false
            this.$emit('trigger_reload', this.return_object)
        },
        async get_users() {
            this.users = await this.api_get_all_items('users', 'name', {
                organization: this.user_extended_data.organization,
            })
        },
        async get_members() {
            const user_ids = []
            const access_group_users = this.selected_access_group.users || []
            for (const x of access_group_users) {
                user_ids.push(x['user'])
            }
            if (!user_ids.length) return
            this.members = await this.api_get_all_items('users', 'name', {
                _id: { $in: user_ids },
                organization: this.user_extended_data.organization,
            })
        },
        async get_administrators() {
            this.administrators = await this.api_get_all_items('users', 'name', {
                'data_access.access_group': this.selected_access_group['_id'],
                organization: this.user_extended_data.organization,
            })
            this.original_administrators = [...this.administrators]
        },
        users_not_in_group(group) {
            const users = this.users.filter((v) => !group.find((x) => x['_id'] === v['_id']))
            if (this.current_tab === 0) {
                return users.filter((v) => {
                    return !this.is_coach(v)
                })
            } else {
                return users.filter((v) => {
                    return this.is_coach(v) && !this.is_unit_administrator(v)
                })
            }
        },
        async add_to_group(group, group_index) {
            if (group_index === 1) {
                const data_access = this.selected_user.data_access || []
                data_access.push({
                    access_group: this.selected_access_group['_id'],
                    from: new Date().toUTCString(),
                    until: add(new Date(), { years: 20 }).toUTCString(),
                })
                this.selected_user['data_access'] = data_access
            }
            group.push(this.selected_user)
            this.selected_user = {}
        },
        async remove_from_group(group, group_index, user, index) {
            if (group_index === 1) {
                const data_access = user.data_access || []
                const delete_index = data_access.findIndex(
                    (v) => v['access_group'] === this.selected_access_group['_id']
                )
                user['data_access'].splice(delete_index, 1)
            }
            group.splice(index, 1)
        },
        async update_users() {
            const updates = []
            for (const administrator of this.administrators) {
                if (this.original_administrators.find((v) => v['_id'] === administrator['_id'])) continue
                const data_access = administrator['data_access'] || []
                for (const access of data_access) {
                    delete access['access_group_name']
                }
                updates.push({
                    _id: administrator['_id'],
                    _etag: administrator['_etag'],
                    data: { data_access: data_access },
                    method: this.api_patch,
                })
            }
            for (const administrator of this.original_administrators) {
                if (!this.administrators.find((v) => v['_id'] === administrator['_id'])) {
                    const data_access = administrator['data_access'] || []
                    for (const access of data_access) {
                        delete access['access_group_name']
                    }
                    const exists = updates.find((v) => v['_id'] === administrator['_id'])
                    if (exists) {
                        exists['data']['data_access'] = data_access
                    } else {
                        updates.push({
                            _id: administrator['_id'],
                            _etag: administrator['_etag'],
                            data: { data_access: data_access },
                            method: this.api_patch,
                        })
                    }
                }
            }

            for (const update of updates) {
                await update.method({
                    url: `users/${update['_id']}`,
                    if_match: update['_etag'],
                    data: update['data'],
                })
            }
        },
        skip_user_and_save() {
            this.show_selected_value_dialog = false
            this.selected_user = {}
            this.save()
        },
        add_user_and_save() {
            this.show_selected_value_dialog = false
            if (this.current_tab === 0) {
                this.add_to_group(this.members, 0)
            } else {
                this.add_to_group(this.administrators, 1)
            }
            this.save()
        },
        ...mapActions(['push_state_property', 'splice_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
